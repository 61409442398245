import axios from 'axios'
import { logger } from '../Middleware/Logger'
import { TemplateObject } from '../Models/TemplateObject'
import { apiConf } from './Constants'

/** For Development */
const development = axios.create({
    baseURL: apiConf.developmentBaseUrlForObject
})

/** For Production */
const production = axios.create({
    baseURL: apiConf.developmentBaseUrlForObject
})

const instance = process.env.NODE_ENV === 'development' ? development : production

export const serviceSuccessHandle = async (res: TemplateObject) => {
    if (res.status >= 400) {
        logger.error('🚀 ~ file: Axios.tsx ~ line 19 ~ serviceSuccessHandle ~ res', res)
        return false
    }
    return res.data
}

export default instance
