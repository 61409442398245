import { LogLevel } from './Middleware/Logger'

/** The App environment */
export type Environment = 'development' | 'production';

export const APP_ENV: Environment = process.env.NODE_ENV === 'development' ? 'development' : 'production'

export const LOG_LEVEL: LogLevel = APP_ENV === 'production' ? 'warn' : 'log'

export const DYNAMIC_INSERT_FLAG = {
    STATIC: 0,
    DYNAMIC_INSERT: 1,
    DYNAMIC_REMOVE: 2
}
