import React, { ReactElement, useContext } from 'react'
import { DEFAULT_VALUE, jsonKeyMapping } from '../../../Config/Constants'

import InputContext from '../../../Contexts/Input.Context'
import useLoadMoreFields from '../../../Hooks/LoadMoreFields'
import { TemplateObject } from '../../../Models/TemplateObject'
import FormBuilder from '../../FormBuilder/FormBuilder'
import ToolTipWithLabelComponent from '../ToolTipWithLabelComponent'

type Props = {
    children: React.ReactNode,
    formElement:TemplateObject,
    label:string
}

const InputWrapper = ({ children, formElement, label }:Props):ReactElement => {
    const { conditionalInput } = useContext(InputContext)
    /* Load More Fields Based Upon Previous Selected Values */
    useLoadMoreFields({ formElement })

    return (
        <div className={`form-group ${formElement.class ? formElement.class : ''}`}>
            {formElement[jsonKeyMapping.FORM_SUB_TOOLTIP]
                ? (
                    <ToolTipWithLabelComponent
                        tooltipClass="tooltip-bulb"
                        labelClass={'form-label text-right'}
                        formElement={formElement}
                    />
                )
                : (
                    <label htmlFor={formElement[jsonKeyMapping.FORM_ID]} className="form-label text-right d-inline">{label}</label>
                )
            }
            {formElement[jsonKeyMapping.FORM_SUB_LABEL] && <div>{formElement[jsonKeyMapping.FORM_SUB_LABEL]}</div> }
            <div className='mt-2'></div>
            <div>
                {children}
            </div>
            {conditionalInput && (
                <FormBuilder
                    formGroup={conditionalInput.formElement}
                    hasParent={{
                        [jsonKeyMapping.FORM_ID]: formElement[jsonKeyMapping.FORM_ID],
                        [jsonKeyMapping.FORM_LABEL]: conditionalInput.parentLabel,
                        showElement: conditionalInput.value || DEFAULT_VALUE
                    }} />
            )}
        </div>
    )
}

export default InputWrapper
