import React, { ReactElement } from 'react'
import FormBuilder from '../../../FormBuilder/FormBuilder'
import { BsX } from 'react-icons/bs'
import { TemplateObject } from '../../../../Models/TemplateObject'
import { jsonKeyMapping } from '../../../../Config/Constants'
import ToolTipWithLabelComponent from '../../ToolTipWithLabelComponent'

type Props = {
    inputFields: TemplateObject
    removeInputField: Function,
    formElement: TemplateObject
}

const AddMoreGroupElements = ({ inputFields, removeInputField, formElement }: Props): ReactElement | null => {
    if (Object.values(inputFields).length === 0) {
        return null
    }

    return (
        <div className='mt-3 position-relative card add-more-body'>
            <div>
                {
                    formElement[jsonKeyMapping.FORM_ADD_MORE_SUB_TOOL_TIP]
                        ? (
                            <ToolTipWithLabelComponent
                                tooltipClass="tooltip-bulb"
                                labelClass={'form-label text-right'}
                                formElement={{
                                    [jsonKeyMapping.FORM_LABEL]: formElement[jsonKeyMapping.FORM_ADD_MORE_GROUP_LABEL],
                                    [jsonKeyMapping.FORM_ID]: formElement[jsonKeyMapping.FORM_ID],
                                    [jsonKeyMapping.FORM_SUB_TOOLTIP]: formElement[jsonKeyMapping.FORM_ADD_MORE_SUB_TOOL_TIP]
                                }}
                            />
                        )
                        : (
                            <label htmlFor={formElement[jsonKeyMapping.FORM_ID]} className="form-label text-right d-inline">{formElement[jsonKeyMapping.FORM_ADD_MORE_GROUP_LABEL]}</label>
                        )
                }
            </div>
            {
                Object.values(inputFields).map((input, index) => {
                    return (
                        <div key={index}>
                            <BsX
                                className="close-icon m-1"
                                onClick={() => removeInputField(input[jsonKeyMapping.FORM_ID])}
                            />
                            <FormBuilder formGroup={[input]} />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default AddMoreGroupElements
