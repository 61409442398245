import React from 'react'
import { TemplateObject } from '../../Models/TemplateObject'

type Props = {
    buttonProps: TemplateObject,
    clickHandler?: React.MouseEventHandler<HTMLButtonElement>
}

const ButtonComponent = ({ buttonProps, clickHandler }: Props) => {
    return (
        <button
            className={buttonProps.className}
            id={buttonProps.id}
            style={buttonProps.style}
            onClick={clickHandler}
        >
            {buttonProps.text}
        </button>
    )
}

export default ButtonComponent
