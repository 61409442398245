import ErrorObject from '../../Models/ErrorObject'
import { TemplateObject } from '../../Models/TemplateObject'

const setTemplatePath = (payload:string) => payload

const loadTemplate = (payload:TemplateObject) => {
    const keys = Object.keys(payload)
    return { keys, payload }
}

const setSelectedState = (payload:string) => payload

const setTemplate = (payload:TemplateObject) => ({})

const setIsLoading = (isLoading:boolean) => isLoading

const setModalState = (show:boolean) => show

const setError = (error:ErrorObject) => error

export default {
    setTemplatePath,
    loadTemplate,
    setSelectedState,
    setTemplate,
    setIsLoading,
    setModalState,
    setError
}
