import React, { useContext, useEffect, useState } from 'react'
import FormDataContext from '../../../Contexts/FormData.context'
import { formActionEnum, jsonKeyMapping } from '../../../Config/Constants'
import Button from 'react-bootstrap/Button'
import { TemplateObject } from '../../../Models/TemplateObject'
import { useParams } from 'react-router-dom'
import InputWrapper from './InputWrapper'
import produce from 'immer'
import InputTextMask from './InputTextMask'
import AddMoreElements from './InputText/AddMoreElements'
import GroupElements from './InputText/GroupElements'
import AddMoreGroupElements from './InputText/AddMoreGroupElements'
import InputContext from '../../../Contexts/Input.Context'

type Props = {
    formElement: TemplateObject;
    handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
    onBlur:any,
    groups:any,
    fieldIndex:any
};

const InputText = ({ formElement, handleInputChange, onBlur, groups, fieldIndex }: Props) => {
    const { state, dispatch } = useContext(FormDataContext)
    const { setConditionalInput } = useContext(InputContext)
    const { formData } = state
    const [inputFields, setInputFields] = useState<TemplateObject>({})
    const [inputFieldGroup, setInputFieldGroup] = useState<TemplateObject>({})
    const [isMaxLimitReached, setMaxLimitReached] = useState<Boolean>(false)

    const { stepName } = useParams()

    /* Load the dynamically added elements */
    useEffect(() => {
        const inputFields: TemplateObject = {}
        if (formElement[jsonKeyMapping.FORM_ADD_MORE] === 'yes') {
            const matchRegex = `${formElement[jsonKeyMapping.FORM_ID]}\\d`
            const re = new RegExp(matchRegex, 'g')
            const filterKeys = Object.keys(formData).filter((key) => key.match(re))

            filterKeys.forEach((id) => {
                if (typeof formData[id] === 'string') {
                    inputFields[id] = {
                        ...formElement,
                        [jsonKeyMapping.FORM_ID]: id,
                        [jsonKeyMapping.FORM_LABEL]: formElement[jsonKeyMapping.FORM_ADD_MORE_LABEL] || formElement[jsonKeyMapping.FORM_LABEL],
                        [jsonKeyMapping.FORM_ADD_MORE]: false,
                        isAddedDynamically: true
                    }
                }
            })
        }
        setInputFields(inputFields)

        /* max limit check */
        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            const dynamicFieldsListLength = Object.keys(inputFields).length
            if (dynamicFieldsListLength >= formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
                setMaxLimitReached(true)
            }
        }
        /* Add meta data information to render add more elements with separator */
        if (jsonKeyMapping.FORM_INPUT_SEPARATOR in formElement &&
            formElement[jsonKeyMapping.FORM_INPUT_SEPARATOR] !== null
        ) {
            const key = `meta-data-${formElement[jsonKeyMapping.FORM_ID]}`
            const metaData: TemplateObject = {
                // [jsonKeyMapping.FORM_ID]: formElement[jsonKeyMapping.FORM_ID],
                separatorId: formElement[jsonKeyMapping.FORM_ID],
                addMoreElement: true,
                separator: formElement[jsonKeyMapping.FORM_INPUT_SEPARATOR]
            }

            /* GROUP ELEMENTS */
            if (formElement[jsonKeyMapping.FORM_ADD_MORE_GROUP]) {
                metaData.children = formElement[jsonKeyMapping.FORM_GROUP_ELEMENT].map((input:TemplateObject) => input[jsonKeyMapping.FORM_ID])
                metaData.isGroupAdd = true
                metaData.groupInputSeparator = formElement[jsonKeyMapping.FORM_GROUP_INPUT_SEPARATOR] || ' '
            }

            const formElementData:TemplateObject = {
                [key]: metaData
            }

            if (formData[key]) {
                formElementData[key] = { ...formData[key], ...metaData }
            }

            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })
        }

        return () => { }
    }, [stepName, formElement])

    /* Load the dynamically group elements */
    useEffect(() => {
        const inputFieldGroup: TemplateObject = {}
        const groupElementMetaKey = `meta-data-${formElement[jsonKeyMapping.FORM_ID]}`
        if (formElement[jsonKeyMapping.FORM_ADD_MORE_GROUP] === 'yes' && formData[groupElementMetaKey] !== undefined) {
            const fields = formElement[jsonKeyMapping.FORM_GROUP_ELEMENT]
            if (formData[groupElementMetaKey].dynamicIndex) {
                const allIndex = formData[groupElementMetaKey].dynamicIndex
                allIndex.forEach((index: number) => {
                    inputFieldGroup[`field-${index}`] = fields.map((field: TemplateObject) => {
                        return {
                            ...field,
                            [jsonKeyMapping.FORM_ID]: `${field[jsonKeyMapping.FORM_ID]}-${index}`
                        }
                    })
                })
            }
        }
        setInputFieldGroup(inputFieldGroup)
        /* max limit check */
        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            const dynamicFieldsListLength = Object.keys(inputFieldGroup).length
            if (dynamicFieldsListLength >= formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
                setMaxLimitReached(true)
            }
        }
        return () => { }
    }, [stepName, formElement])

    /* remove add fields Element */
    const removeInputField = (id: string) => {
        const inputFieldsKeys = Object.keys(inputFields)
        const startIndex = inputFieldsKeys.indexOf(id) + 1
        const updatedValues: TemplateObject = {}
        // eslint-disable-next-line no-unused-vars
        const [key, _index] = id.split(/(\d)/)
        /* rearrange the values  */
        for (let index = startIndex; index < inputFieldsKeys.length; index++) {
            updatedValues[inputFieldsKeys[index - 1]] = formData[`${key}${index}`]
        }
        /* update rearrange values */
        if (Object.keys(updatedValues).length > 0) {
            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: updatedValues })
            /* delete the end value */
            const deletingIndex = inputFieldsKeys[inputFieldsKeys.length - 1]
            dispatch({ type: formActionEnum.REMOVE_FORM_DATA, payload: [deletingIndex] })
            setInputFields((state: TemplateObject) => {
                delete state[deletingIndex]
                return state
            })
        } else {
            /* Delete the previous set form data */
            dispatch({ type: formActionEnum.REMOVE_FORM_DATA, payload: [id] })
            setInputFields((state: TemplateObject) => {
                delete state[id]
                return state
            })
        }

        /* max limit check */
        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            setMaxLimitReached(false)
        }
    }
    /* add fields Element */
    const addFormElement = () => {
        /* set blank value for separator  */
        if (jsonKeyMapping.FORM_INPUT_SEPARATOR in formElement &&
            formElement[jsonKeyMapping.FORM_INPUT_SEPARATOR] !== null &&
            formData[formElement[jsonKeyMapping.FORM_ID]] === undefined
        ) {
            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: { [formElement[jsonKeyMapping.FORM_ID]]: '' } })
        }

        /* max limit check */
        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            const dynamicFieldsListLength = Object.keys(inputFields).length
            if (dynamicFieldsListLength >= formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
                return false
            }
        }

        let elementIndex = 0
        Object.keys(inputFields).forEach((key) => {
            if (formData[`${formElement[jsonKeyMapping.FORM_ID]}${elementIndex}`] !== undefined) {
                elementIndex++
            }
        })
        const newID = `${formElement[jsonKeyMapping.FORM_ID]}${elementIndex}`
        const newElement = {
            [newID]: {
                ...formElement,
                [jsonKeyMapping.FORM_LABEL]: formElement[jsonKeyMapping.FORM_ADD_MORE_LABEL] || formElement[jsonKeyMapping.FORM_LABEL],
                [jsonKeyMapping.FORM_ID]: newID,
                [jsonKeyMapping.FORM_ADD_MORE]: false,
                isAddedDynamically: true,
                [jsonKeyMapping.FORM_INPUT_SEPARATOR]: null
            }
        }
        /* Dynamic Field in formData */
        const formElementData: TemplateObject = {
            [newID]: ''
        }
        dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })
        setInputFields((state) => ({ ...state, ...newElement }))
        /* max limit check */
        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            const dynamicFieldsListLength = Object.keys(inputFields).length + 1
            if (dynamicFieldsListLength === formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
                setMaxLimitReached(true)
            }
        }
    }

    /* Add group fields Elements */
    const addGroupFormElement = (fields: TemplateObject[]) => {
        /* set blank value for separator  */
        if (jsonKeyMapping.FORM_INPUT_SEPARATOR in formElement &&
            formElement[jsonKeyMapping.FORM_INPUT_SEPARATOR] !== null &&
            formData[formElement[jsonKeyMapping.FORM_ID]] === undefined
        ) {
            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: { [formElement[jsonKeyMapping.FORM_ID]]: '' } })
        }
        /* max limit check */
        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            const dynamicFieldsListLength = Object.keys(inputFieldGroup).length
            if (dynamicFieldsListLength >= formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
                return false
            }
        }

        let elementIndex = 0
        /* Dynamic Field in formData */
        const formElementData: TemplateObject = {}
        /* TO KEEP TRACK OF ALL DYNAMIC ADD ELEMENTS */
        const groupElementMetaKey = `meta-data-${formElement[jsonKeyMapping.FORM_ID]}`
        if (formData[groupElementMetaKey] === undefined || formData[groupElementMetaKey].dynamicIndex === undefined) {
            formElementData[groupElementMetaKey] = { dynamicIndex: [elementIndex] }
        } else {
            const previousIndex = formData[groupElementMetaKey].dynamicIndex
            elementIndex = previousIndex[previousIndex.length - 1] + 1
            formElementData[groupElementMetaKey] = { dynamicIndex: [...previousIndex, elementIndex] }
        }

        const updateFields = fields.map((field: TemplateObject) => {
            return {
                ...field,
                [jsonKeyMapping.FORM_ID]: `${field[jsonKeyMapping.FORM_ID]}-${elementIndex}`
            }
        })

        if (formData[groupElementMetaKey]) {
            formElementData[groupElementMetaKey] = { ...formData[groupElementMetaKey], ...formElementData[groupElementMetaKey] }
        }

        updateFields.forEach((field: TemplateObject) => (formElementData[field[jsonKeyMapping.FORM_ID]] = ''))
        setInputFieldGroup(val => ({ ...val, [`field-${elementIndex}`]: updateFields }))
        dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })

        /* max limit check */
        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            const dynamicFieldsListLength = Object.keys(inputFieldGroup).length + 1
            if (dynamicFieldsListLength === formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
                setMaxLimitReached(true)
            }
        }
    }

    /* Remove group fields  Elements */
    const removeGroupFields = (key: string) => {
        const inputFieldsKeys = Object.keys(inputFieldGroup)
        const startIndex = inputFieldsKeys.indexOf(key) + 1
        const updatedValues: TemplateObject = {}
        // eslint-disable-next-line no-unused-vars
        const [id, _index] = key.split(/(\d)/)
        const ids: string[] = inputFieldGroup[key].map((field: TemplateObject) => {
            // eslint-disable-next-line no-unused-vars
            const [id, _index] = field[jsonKeyMapping.FORM_ID].split('-')
            return id
        })

        for (let index = startIndex; index < inputFieldsKeys.length; index++) {
            ids.forEach((id: string) => {
                updatedValues[`${id}-${index - 1}`] = formData[`${id}-${index}`]
            })
        }

        if (Object.keys(updatedValues).length > 0) {
            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: updatedValues })
            /* delete the end value */
            const deletingIndex = inputFieldsKeys[inputFieldsKeys.length - 1]
            const deleteIds: string[] = inputFieldGroup[key].map((field: TemplateObject) => {
                // eslint-disable-next-line no-unused-vars
                const [id, _index] = field[jsonKeyMapping.FORM_ID].split('-')
                return `${id}-${inputFieldsKeys.length - 1}`
            })

            const groupElementMetaKey = `meta-data-${formElement[jsonKeyMapping.FORM_ID]}`
            const dynamicIndex = formData[groupElementMetaKey].dynamicIndex
            const filterIndex = formData[groupElementMetaKey].dynamicIndex.filter((index: number) => (index !== dynamicIndex.length - 1))
            const formElementData: TemplateObject = {
                [groupElementMetaKey]: { dynamicIndex: filterIndex }
            }
            if (formData[groupElementMetaKey]) {
                formElementData[groupElementMetaKey] = { ...formData[groupElementMetaKey], ...formElementData[groupElementMetaKey] }
            }
            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })
            dispatch({ type: formActionEnum.REMOVE_FORM_DATA, payload: deleteIds })
            setInputFieldGroup(val => (produce(val, draft => {
                delete draft[deletingIndex]
            })))
        } else {
            const keys: string[] = []
            const fields = inputFieldGroup[key]
            fields.forEach((field: TemplateObject) => keys.push(field[jsonKeyMapping.FORM_ID]))
            // eslint-disable-next-line no-unused-vars
            const [_key, removeDynamicIndex] = key.split('field-')
            const groupElementMetaKey = `meta-data-${formElement[jsonKeyMapping.FORM_ID]}`
            const filterIndex = formData[groupElementMetaKey].dynamicIndex.filter((index: number) => (index !== Number(removeDynamicIndex)))

            const formElementData: TemplateObject = {}
            if (filterIndex.length > 0) {
                formElementData[groupElementMetaKey] = { dynamicIndex: filterIndex }
            } else {
                formElementData[groupElementMetaKey] = {}
            }

            if (formData[groupElementMetaKey]) {
                formElementData[groupElementMetaKey] = { ...formData[groupElementMetaKey], ...formElementData[groupElementMetaKey] }
            }
            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })

            dispatch({ type: formActionEnum.REMOVE_FORM_DATA, payload: keys })
            setInputFieldGroup(val => (produce(val, draft => {
                delete draft[key]
            })))
        }
        /* max limit check */
        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            setMaxLimitReached(false)
        }
    }

    /* add more fields */
    const loadFields = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = e.target.value
        /* Valid if validation is provided */
        if (
            inputText !== '' &&
            formElement[jsonKeyMapping.FORM_VALIDATION_REGEX]
        ) {
            const regex = new RegExp(formElement[jsonKeyMapping.FORM_VALIDATION_REGEX], 'g')
            if (!inputText.match(regex)) return false
        }

        if (e.target.value !== '' && formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] !== undefined) {
            setConditionalInput({
                formElement: formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS],
                parentLabel: formElement[jsonKeyMapping.FORM_LABEL],
                value: formElement[jsonKeyMapping.FORM_VALUE]
            })
        } else {
            setConditionalInput(null)
        }
        handleInputChange(e)
    }

    return (
        <InputWrapper
            formElement={formElement}
            label={formElement[jsonKeyMapping.FORM_LABEL] || ''}
        >
            {(formElement[jsonKeyMapping.FORM_PREFIX_SYMBOL] || formElement[jsonKeyMapping.FORM_SUFFIX_SYMBOL])
                ? (<InputTextMask
                    formElement={formElement}
                    handleInputChange={loadFields}
                />)
                : (<input
                    id={formElement[jsonKeyMapping.FORM_ID]}
                    className={`form-control ${formElement.inputClass ? formElement.inputClass : ''
                    }`}
                    placeholder={formElement[jsonKeyMapping.FORM_PLACEHOLDER] || ''}
                    type={formElement.type}
                    onChange={loadFields}
                    title={fieldIndex || 0}
                    onBlur={(e) => {
                        const label = formElement[jsonKeyMapping.FORM_LABEL] || ''
                        onBlur(e, label, formElement)
                    }}
                    maxLength={formElement.maxLength || formElement[jsonKeyMapping.FORM_MAX_LENGTH] || null}
                    value={formData[formElement[jsonKeyMapping.FORM_ID]] ? formData[formElement[jsonKeyMapping.FORM_ID]] : ''}
                />)}
            {/* ADD MORE Fields */}
            {
                formElement[jsonKeyMapping.FORM_ADD_MORE_GROUP_LABEL]
                    ? (<AddMoreGroupElements formElement={formElement} inputFields={inputFields} removeInputField={removeInputField} />)
                    : (<AddMoreElements inputFields={inputFields} removeInputField={removeInputField} />)
            }
            {/* ADD More Action Btn */}
            {(formElement[jsonKeyMapping.FORM_ADD_MORE] && isMaxLimitReached === false) &&
                <Button
                    className="mt-2 btn-blue add-button"
                    onClick={addFormElement}
                >
                    Add More
                </Button>
            }
            {/* Load the group Element */}
            {<GroupElements inputFieldGroup={inputFieldGroup} removeGroupFields={removeGroupFields} groups={groups} />}
            {(formElement[jsonKeyMapping.FORM_ADD_MORE_GROUP] && isMaxLimitReached === false) &&
                <Button
                    className="mt-2 btn-blue"
                    onClick={() => addGroupFormElement(formElement[jsonKeyMapping.FORM_GROUP_ELEMENT])}
                >
                    Add More
                </Button>
            }
        </InputWrapper>
    )
}

export default InputText
