import React from 'react'
import { jsonKeyMapping } from '../../../Config/Constants'
import { TemplateObject } from '../../../Models/TemplateObject'
import FaqComponent from '../../Shared/FaqComponent'
import PreviewComponent from '../../Shared/PreviewComponent'
import Template from '../../Template/Template'

type Props = {
    template: string;
    stepDetails: TemplateObject;
};

const FormPreview = ({ template, stepDetails }: Props) => {
    return (
        <>
            {stepDetails[jsonKeyMapping.FORM_GROUP] &&
                stepDetails[jsonKeyMapping.FORM_GROUP].length > 0 && (
                <div className="col-lg-3 previewdisplay lgwidth">
                    <div className="card scrollable-div leftsidebar">
                        <div className="card-header headerheight"></div>
                        {/* ACTION BUTTON */}
                        <div className="preview-heading d-flex justify-content-md-end p-20 bg-grey">
                            <PreviewComponent />
                            {stepDetails[jsonKeyMapping.FAQ] &&
                                    stepDetails[jsonKeyMapping.FAQ].length > 0 && (
                                <FaqComponent
                                    faqContentList={stepDetails[jsonKeyMapping.FAQ]}
                                />
                            )}
                        </div>
                        {/* FORM LIVE PREVIEW */}
                        <div className="card-body bg-grey p-0 ps-3">
                            <div className="form_design">
                                <div className="formpreview">
                                    <Template templateKey={template} isInnerPage />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default FormPreview
