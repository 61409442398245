import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { sessionStorageKey } from '../../Config/Constants'

type Props = {
    // eslint-disable-next-line no-undef
    children: JSX.Element
}

const SkipSelectState = ({ children }: Props) => {
    const { pathname } = useLocation()
    const state = sessionStorage.getItem(sessionStorageKey.SELECTED_STATE) || ''

    if (state === '') return children
    return <Navigate to={`${pathname}/${state}`} replace />
}

export default SkipSelectState
