import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProgressBar } from 'react-bootstrap'
import TemplateDataContext from '../../../Contexts/TemplateData.context'
import { TemplateObject } from '../../../Models/TemplateObject'
import DropDownNavbar from './DropDownNavbar'
import NavBarItem from './NavBarItem'
import { jsonKeyMapping } from '../../../Config/Constants'

type Props = {
    uri: string,
    progressIndicator: number,
    show: boolean,
    isHideSteps: string[]
}

const Nav = ({ uri, progressIndicator, show, isHideSteps }: Props) => {
    const { state } = useContext(TemplateDataContext)
    const { activeTemplateGroupBy, activeTemplateSteps } = state
    const [navbarSteps, setNavbarSteps] = useState<object[]>([])
    const { stepName } = useParams()
    let isVisited = false

    useEffect(() => {
        const navSteps:object[] = []
        activeTemplateGroupBy.length > 0 && activeTemplateGroupBy.forEach((item:any) => {
            if (isHideSteps && !isHideSteps.includes(item.slugify)) {
                navSteps.push(item)
            }
        })
        setNavbarSteps(navSteps)
    }, [state])

    return (
        <>
            <div className="p-20 blue-color progressnone">Progress</div>
            <div className="pe-3 ps-13rem pb-20 blue-color progressnone">
                <ProgressBar variant="primary" className="progress-indicator" now={progressIndicator} ></ProgressBar>
                <div className='mt-2'>
                    {Math.round(progressIndicator)}%
                </div>
            </div>
            {/* TODO Remove Header in side bar and add Cross icon in place of Toggle Button */}
            <div id="navbarNav" className={show ? 'show' : ''}>
                {show
                    ? (
                        <>
                            <div className="p-20 blue-color">Progress</div>
                            <div className="pe-3 ps-13rem pb-20 blue-color">
                                <ProgressBar variant="primary" className="progress-indicator" now={progressIndicator} ></ProgressBar>
                                <div className='mt-2'>
                                    {Math.round(progressIndicator)}%
                                </div>
                            </div>
                        </>)
                    : null}
                {/* TODO:CSS : Arrow Icon */}
                <ul className="list-group scrollable-div">
                    {navbarSteps.map((step:TemplateObject, stepPositionIndex:number) => {
                        if (step.groupName !== undefined) {
                            return (
                                <DropDownNavbar
                                    uri={uri}
                                    groupStepList={step}
                                    groupName={step.groupName}
                                    key={stepPositionIndex}
                                />
                            )
                        }

                        const isActive = stepName === step[jsonKeyMapping.SLUGIFY]
                        /* Find the current step index */
                        const stepIndex = activeTemplateSteps.findIndex((step:TemplateObject) => step[jsonKeyMapping.SLUGIFY] === stepName)
                        /* compare the current step index with the steps   */
                        for (let index = 0; index < activeTemplateSteps.length; index++) {
                            if (activeTemplateSteps[index][jsonKeyMapping.SLUGIFY] === step[jsonKeyMapping.SLUGIFY]) {
                                stepIndex > index ? isVisited = true : isVisited = false
                            }
                        }

                        return (
                            <NavBarItem
                                isVisited={isVisited}
                                isActive={isActive}
                                step={step}
                                uri={uri}
                                key={stepPositionIndex} />
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export default Nav
