import React, { ReactElement, useState, createContext } from 'react'
import { TemplateObject } from '../Models/TemplateObject'

const InputContext = createContext<{
    conditionalInput : null|TemplateObject,
    setConditionalInput:Function
}>({
    conditionalInput: null,
    setConditionalInput: (value:TemplateObject) => {}
})

type Props = {
    children: React.ReactNode,
}

export const InputContextProvider = ({ children }: Props): ReactElement => {
    const [conditionalInput, setConditionalInput] = useState<null|TemplateObject>(null)
    return (
        <InputContext.Provider value={{ conditionalInput, setConditionalInput }}>
            {children}
        </InputContext.Provider>
    )
}

export default InputContext
