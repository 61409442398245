import React, { useContext } from 'react'
import ColorComponent from './Color/ColorComponent'
import { colors, formActionEnum } from '../../Config/Constants'
import { Color } from '../../Models/Color'
import FormDataContext from '../../Contexts/FormData.context'

type Props = {}

const ColorSelector = (props: Props) => {
    const { dispatch } = useContext(FormDataContext)

    // Set Active Color from ColorComponent
    const handleClick: Function = (e: React.ChangeEvent, colorProps: Color): void => {
        e.preventDefault()
        let activeColor
        activeColor = colors[0]
        // Set the selected color
        if (colorProps.colorName !== 'htmlThemeReset') {
            activeColor = colors.find(color => color.colorHashValue === colorProps.colorHashValue)
        }
        if (activeColor) {
            dispatch({ type: formActionEnum.SET_COLOR, payload: activeColor })
        }
    }
    return (
        <div className="selector-wrap">
            <ul className="color-selector">
                {colors.map((color, index) => (
                    <ColorComponent key={`colorIndex_${index}`} colorProps={color} handleClick={handleClick} />
                ))}
            </ul>
        </div>
    )
}

export default ColorSelector
