import React, { createContext, useReducer } from 'react'
import { sessionStorageKey } from '../Config/Constants'
import { logger } from '../Middleware/Logger'
import FormState from '../Models/FormState'
import defaultState from '../State/Form/defaultState'
import FormReducer from '../State/Form/Reducer'

// Create Context
const FormDataContext = createContext<FormState>({
    state: defaultState,
    dispatch: () => {}
})

type Props = {
    children:React.ReactNode
}

export const FormDataContextProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(FormReducer, defaultState, () => {
        try {
            const saveState = sessionStorage.getItem(sessionStorageKey.FORM_STATE)
            if (saveState) {
                return JSON.parse(saveState)
            }
            return defaultState
        } catch (error) {
            logger.error('🚀 ~ file: TemplateDataContext.context.tsx ~ line 18 ~ useReducer ~ error', error)
        }
    })

    return (
        <FormDataContext.Provider value={{ state, dispatch }}>
            {children}
        </FormDataContext.Provider>
    )
}

export default FormDataContext
