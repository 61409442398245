import React, { ReactElement, useContext } from 'react'
import FormBuilder from '../../../FormBuilder/FormBuilder'
import { BsX } from 'react-icons/bs'
import { TemplateObject } from '../../../../Models/TemplateObject'
import FormDataContext from '../../../../Contexts/FormData.context'
import { useParams } from 'react-router-dom'
import { formActionEnum } from '../../../../Config/Constants'

type Props = {
    inputFieldGroup: TemplateObject
    removeGroupFields: Function,
    groups:any
}

const GroupElements = ({ inputFieldGroup, removeGroupFields, groups }: Props): ReactElement => {
    const { state, dispatch } = useContext(FormDataContext)
    const { stepName } = useParams()
    const { formData } = state

    const removeStepKey = (index:any) => {
        const allFormData = { ...formData }
        const currentStep = stepName || ''
        const updateData = [...allFormData[currentStep]]
        const removedItem:any = []
        const currentIndex = index + 1

        updateData.forEach((item, i) => {
            if (currentIndex !== i) {
                removedItem.push(item)
            }
        })

        allFormData[currentStep] = removedItem

        dispatch({ type: formActionEnum.SET_FORM_DATA, payload: allFormData })
    }
    return (<>
        {
            Object.keys(inputFieldGroup).map((field: string, fieldIndex: number) => {
                return (
                    <div key={fieldIndex} className="mt-3 position-relative card add-more-body">
                        <BsX
                            className="close-icon mb-2 m-2 position-absolute top-0 end-0 "
                            size={24}
                            onClick={() => {
                                removeGroupFields(field)
                                removeStepKey(fieldIndex)
                            }}
                        />
                        <FormBuilder fieldIndex={fieldIndex + 1} formGroup={inputFieldGroup[field]} groups={groups} />
                    </div>
                )
            })
        }
    </>)
}

export default GroupElements
