import React, { ReactElement } from 'react'
import { jsonKeyMapping } from '../../../Config/Constants'
import { TemplateObject } from '../../../Models/TemplateObject'
import Card from 'react-bootstrap/Card'

type Props = {
    formElement: TemplateObject
}

const InputInformation = ({ formElement }: Props): ReactElement => {
    return (
        <Card body className='m1-1 p-0'>
            {formElement[jsonKeyMapping.FORM_LABEL]}
        </Card>
    )
}

export default InputInformation
