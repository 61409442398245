import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ChooseTemplate } from '../../Models/ChooseTemplate'
import { ROOT_PATH } from '../../Config/Constants'

export default function ChooseTemplateCard ({ title, subTitle, link }:ChooseTemplate) : ReactElement {
    return (
        <div className="col-md-6 mb-3 w-100">
            <Link to={`/${ROOT_PATH}/${link}`}
                className="template-link">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">{title}</h5>
                        <p className="card-text">{subTitle}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}
