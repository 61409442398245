import React, { useContext } from 'react'
import FormDataContext from '../../../Contexts/FormData.context'
import { Color } from '../../../Models/Color'

type Props = {
    colorProps: Color;
    handleClick: Function
}

const ColorComponent = ({ colorProps, handleClick }: Props) => {
    const { state } = useContext(FormDataContext)
    const { activeColor } = state
    const isActive = activeColor && activeColor.colorHashValue && activeColor.colorHashValue === colorProps.colorHashValue
    const isReset = colorProps && colorProps.colorName === 'htmlThemeReset'
    const style = {
        background: `#${colorProps.colorHashValue}`
    }
    return (
        <li className="item" data-toggle="tooltip">
            <label className={`label ${isActive ? 'active' : ''}${isReset ? 'selector-reset' : ''}`}
                title="Select Theme Color">
                <span className="selector-radio" style={style}></span>
                <input type="radio" value={colorProps.colorHashValue} onClick={(e) => handleClick(e, colorProps)} />
            </label>
        </li>
    )
}

export default ColorComponent
