import _ from 'lodash'
import slugify from 'slugify'
import { jsonKeyMapping } from '../Config/Constants'
import LoadTemplate from '../Models/LoadTemplate'
import { TemplateObject } from '../Models/TemplateObject'
import { fetchTemplates } from '../Services/TemplateServices'

const mapCallback = (titleDict: Map<string, number>): TemplateObject => (step: TemplateObject) => {
    const tempStep = { ...step }
    /* if title with same name already exists then add index */
    if (!titleDict.has(tempStep[jsonKeyMapping.SLUG])) {
        titleDict.set(tempStep[jsonKeyMapping.SLUG], 1)
        tempStep[jsonKeyMapping.SLUGIFY] = slugify(tempStep[jsonKeyMapping.SLUG].toLowerCase())
    } else {
        const index = titleDict.get(tempStep[jsonKeyMapping.SLUG])
        tempStep[jsonKeyMapping.SLUGIFY] = slugify(`${tempStep[jsonKeyMapping.SLUG]}-${index}`.toLowerCase())
        titleDict.set(tempStep[jsonKeyMapping.SLUG], index || 0 + 1)
    }
    return tempStep
}

const modifiedTemplateJson = (templateData : TemplateObject) => {
    /* Find the non-empty content in template */
    let nonEmptyTemplateKey = ''
    for (const key in templateData) {
        if (!_.isEmpty(templateData[key])) {
            nonEmptyTemplateKey = key
            break
        }
    }
    const titleDict = new Map<string, number>()
    // Add slugify to the json data
    for (const singleTemplate in templateData) {
        if (templateData[singleTemplate] &&
            !_.isEmpty(templateData[singleTemplate]) &&
            templateData[singleTemplate][jsonKeyMapping.STEPS] &&
            templateData[singleTemplate][jsonKeyMapping.STEPS].length) {
            templateData[singleTemplate][jsonKeyMapping.STEPS] = templateData[singleTemplate][jsonKeyMapping.STEPS].map(mapCallback(titleDict))
        } else {
            /* if the template content is empty then replace with content one   */
            templateData[singleTemplate] = templateData[nonEmptyTemplateKey]
        }
    }
    return templateData
}

const skipFirstStepJson = (templateData:TemplateObject) => {
    for (const key in templateData) {
        if (templateData[key] &&
            !_.isEmpty(templateData[key]) &&
            templateData[key][jsonKeyMapping.STEPS] &&
            templateData[key][jsonKeyMapping.STEPS].length) {
            templateData[key][jsonKeyMapping.STEPS] = templateData[key][jsonKeyMapping.STEPS].filter((x:TemplateObject, i:number) => i !== 0)
            break
        }
    }
    return templateData
}

const loadTemplate = async ({ endPoint, jsonFileName, config } : LoadTemplate) => await fetchTemplates({ endPoint, jsonFileName, config })

export {
    loadTemplate,
    modifiedTemplateJson,
    skipFirstStepJson
}
