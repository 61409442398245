import axios from 'axios'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { jsonKeyMapping, templateActionEnum } from '../../../Config/Constants'
import FormDataContext from '../../../Contexts/FormData.context'
import TemplateDataContext from '../../../Contexts/TemplateData.context'
import { logger } from '../../../Middleware/Logger'
import { TemplateObject } from '../../../Models/TemplateObject'
import { processFormRequest } from '../../../Services/TemplateServices'
import ErrorMessage from '../../../Util/ErrorMessage'
import FormBuilder from '../../FormBuilder/FormBuilder'
import ButtonComponent from '../../Shared/ButtonComponent'
import FaqComponent from '../../Shared/FaqComponent'
import PreviewComponent from '../../Shared/PreviewComponent'
import StepBoxLeft from '../../StepContent/StepContentHeader/StepBoxLeft'
import Spinner from 'react-bootstrap/Spinner'

type Props = {
    nextBtn: React.MouseEventHandler<HTMLButtonElement>,
    backBtn: React.MouseEventHandler<HTMLButtonElement>,
    stepDetails: TemplateObject,
    isHideSteps: string[],
    isLoading: boolean
}

const MainForm = ({ nextBtn, backBtn, stepDetails, isHideSteps, isLoading }: Props) => {
    const { state, dispatch } = useContext(TemplateDataContext)
    const { state: FormState } = useContext(FormDataContext)
    const { formData } = FormState
    const { templates, activeTemplateSteps } = state
    const { template, stepName, state: templateState, templateType } = useParams()

    const [hasNext, setHasNext] = useState(false)
    const [hasPrevious, setHasPrevious] = useState(false)
    const [hasSubmit, setHasSubmit] = useState(false)

    const formSubmit = async () => {
        const cancelToken = axios.CancelToken.source()
        const config = { cancelToken: cancelToken.token }
        try {
            const submitFormData : TemplateObject = {
                template,
                templateName: templateType,
                state: templateState || '',
                formData: {}
            }
            for (const key in formData) {
                if (!key.match('meta-data-')) {
                    submitFormData.formData[key] = formData[key]
                }
            }
            const { data } = await processFormRequest({ formData: submitFormData, config })
            const { redirectUrl } = data
            window.location.href = redirectUrl
        } catch (error) {
            if (!axios.isCancel(error)) {
                logger.error('🚀 ~ file: useEffect.tsx ~ line 47 ~ changeLayout ~ error', error)
                dispatch({ type: templateActionEnum.SET_ERROR, payload: { show: true, message: ErrorMessage.PROCESSING_FORM } })
            }
        }
    }

    useEffect(() => {
        // if that step provide hasNext or previous then go with it
        if (stepDetails[jsonKeyMapping.HAS_PREVIOUS] !== undefined || stepDetails[jsonKeyMapping.HAS_NEXT] !== undefined || stepDetails[jsonKeyMapping.HAS_SUBMIT] !== undefined) {
            setHasNext(stepDetails[jsonKeyMapping.HAS_NEXT] || false)
            setHasPrevious(stepDetails[jsonKeyMapping.HAS_PREVIOUS] || false)
            setHasSubmit(stepDetails[jsonKeyMapping.HAS_SUBMIT] || false)
        } else if (templates && template && stepName && activeTemplateSteps.length > 0) {
            // if that step does not provide hasNext and hasPrevious then
            const indexOfThisStep = _.findIndex(activeTemplateSteps, { [jsonKeyMapping.SLUGIFY]: stepName })
            // if first step then next = true, prev = false , submit = false
            if (indexOfThisStep === 0) {
                setHasNext(true)
                setHasPrevious(false)
                setHasSubmit(false)
            } else if (indexOfThisStep === activeTemplateSteps.length - 1) {
                // if last step then next = false, prev = true , submit = true
                setHasNext(false)
                setHasPrevious(true)
                setHasSubmit(true)
            } else if (indexOfThisStep > 0 && indexOfThisStep < activeTemplateSteps.length) {
                // if step is not last and not first then set next and previous visible
                setHasNext(true)
                setHasPrevious(true)
                setHasSubmit(false)
            }
        }

        return () => { }
    }, [stepDetails, activeTemplateSteps])

    const step : string = stepName as string

    return (
        <div
            className={`${stepDetails[jsonKeyMapping.FORM_GROUP] && stepDetails[jsonKeyMapping.FORM_GROUP].length > 0 ? 'col-lg-6' : isHideSteps && isHideSteps.includes(step) ? 'col-lg-12' : 'col-lg-9'} pe-0  ${isHideSteps && !isHideSteps.includes(step) && 'offsetmargin'}`}>
            <div className="px-3 scrollable-div leftsidebar h-100">
                {/* TEMPLATE BODY */}
                <div className="card-body bg-grey pt-0 h-75">
                    <div className="headerheight"></div>
                    {/* TEMPLATE Header */}
                    {stepDetails[jsonKeyMapping.FORM_GROUP] && stepDetails[jsonKeyMapping.FORM_GROUP].length > 0
                        ? (
                            <div className="bg-grey pb-20">
                                <p className="font-22 fw-600 blue-color">
                                    {stepDetails[jsonKeyMapping.SLUG]}
                                </p>
                            </div>
                        )
                        : (
                            <>
                                <div className="col-md-12">
                                    <div>
                                        <div className="font-22 fw-600 blue-color text-center mb-2">
                                            {stepDetails[jsonKeyMapping.STEPS_BOX_LEFT] && (
                                                <img src="/images/box-icon-first.png" className="m-2" />
                                            )}
                                            <h4 className="lg-font blue-color">
                                                {stepDetails[jsonKeyMapping.SLUG]}
                                            </h4>
                                        </div>
                                        <p className="text-center mb-20">
                                            {stepDetails[jsonKeyMapping.DESCRIPTION]
                                                ? stepDetails[jsonKeyMapping.DESCRIPTION]
                                                : ''}
                                        </p>
                                    </div>
                                    <div className="text-center">
                                        <h5 className="fw-600 body-font2">
                                            {stepDetails[jsonKeyMapping.TITLE_SECOND]
                                                ? stepDetails[jsonKeyMapping.TITLE_SECOND]
                                                : ''}
                                        </h5>
                                    </div>
                                    <div>
                                        {stepDetails[jsonKeyMapping.STEPS_BOX_LEFT] && (
                                            <StepBoxLeft
                                                subText={stepDetails[jsonKeyMapping.STEPS_BOX_LEFT]}
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        )}

                    <div className="profilerightspace h-100">
                        <div className="row main-field align-content: baseline">
                            <div className='col-12'>
                                <div className='row'>

                                    <div className='col-12'></div>
                                    {/* FORM CONTENT */}
                                    {stepDetails[jsonKeyMapping.FORM_GROUP] &&
                                        stepDetails[jsonKeyMapping.FORM_GROUP].length > 0 && (
                                        <>
                                            <FormBuilder
                                                formGroup={stepDetails[jsonKeyMapping.FORM_GROUP]}
                                                groups={stepDetails[jsonKeyMapping.FORM_GROUP]}
                                                stepDetails={stepDetails}
                                            />
                                        </>
                                    )}
                                    {/* ACTION BUTTON FOR MOBILE VIEW */}
                                    <div className="pb-20 pt-3 bg-grey previewmbdispaly">
                                        {((stepDetails[jsonKeyMapping.FORM_GROUP] &&
                                            stepDetails[jsonKeyMapping.FORM_GROUP].length > 0) ||
                                            stepDetails[jsonKeyMapping.STEPS_BOX_LEFT]) && (
                                            <PreviewComponent />
                                        )}
                                        {stepDetails[jsonKeyMapping.FAQ] &&
                                            stepDetails[jsonKeyMapping.FAQ].length > 0 && (
                                            <FaqComponent
                                                faqContentList={stepDetails[jsonKeyMapping.FAQ]}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* Navigation Button */}
                {isLoading
                    ? <div className="row button-field">
                        <div className="col-md-12 d-flex justify-content-center align-items-center mt-4 mb-2rem" >
                            <Spinner animation="border" className='spinner' style={{ width: '3rem', height: '3rem', textAlign: 'center', margin: 10 }} />
                        </div>
                    </div>
                    : <div className="row button-field">
                        <div
                            className={`col-md-12 d-flex justify-content-${(hasNext && hasPrevious) || (hasSubmit && hasPrevious)
                                ? 'between'
                                : 'center'
                            } align-items-center mt-4 mb-2rem`}
                        >
                            {hasPrevious && (
                                <ButtonComponent
                                    buttonProps={{
                                        className: 'btn btn-white',
                                        id: 'prevBtn',
                                        style: {},
                                        text: 'BACK'
                                    }}
                                    clickHandler={backBtn}
                                />
                            )}
                            {hasNext && (
                                <ButtonComponent
                                    buttonProps={{
                                        className: 'btn primary-button btn-orange',
                                        id: 'nextBtn',
                                        style: { marginRight: '1%' },
                                        text: 'NEXT'
                                    }}
                                    clickHandler={nextBtn}
                                />
                            )}
                            {hasSubmit && (
                                <ButtonComponent
                                    buttonProps={{
                                        className: 'btn primary-button btn-orange',
                                        id: 'submitBtn',
                                        style: {},
                                        text: 'SUBMIT'
                                    }}
                                    clickHandler={formSubmit}
                                />
                            )}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default MainForm
