import React from 'react'
import { Link } from 'react-router-dom'
import { ROOT_PATH } from '../../Config/Constants'

type Props = {}

const Footer = (props: Props) => {
    const currentYear = new Date().getFullYear()
    return (
        <footer id="minimal-footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 pull-left ul-element">
                        <ul className="list-inline text-center mb-10">
                            <li className="list-inline-item border-end pe-2">
                                <Link to={`/${ROOT_PATH}/privacy-policy`} className="list-inline-item text-decoration-underline body-font">
                                Privacy Policy
                                </Link>
                            </li>
                            <li className="list-inline-item border-end pe-2">
                                <Link to={`/${ROOT_PATH}/terms-conditions`} className="text-decoration-underline body-font">
                                Terms and Conditions
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to={`/${ROOT_PATH}/contactUs`} className="text-decoration-underline body-font">
                                Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-12 pull-right">
                        <p className="text-center body-font">© {currentYear}, WorkSimpli Software, LLC. a subsidiary of LifeMD Inc., All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
