/* eslint-disable no-unused-vars */
import { Color } from '../Models/Color'

const colors: Color[] = [
    {
        colorName: 'htmlThemeColor',
        colorHashValue: '000000'
    },
    {
        colorName: 'htmlThemeColor',
        colorHashValue: '576d7b'
    },
    {
        colorName: 'htmlThemeColor',
        colorHashValue: 'bca97e'
    },
    {
        colorName: 'htmlThemeColor',
        colorHashValue: '1a409a'
    },
    {
        colorName: 'htmlThemeColor',
        colorHashValue: '0187de'
    },
    {
        colorName: 'htmlThemeColor',
        colorHashValue: '39c3b1'
    },
    {
        colorName: 'htmlThemeColor',
        colorHashValue: 'd0021b'
    },
    {
        colorName: 'htmlThemeColor',
        colorHashValue: 'fe7a66'
    },
    {
        colorName: 'htmlThemeColor',
        colorHashValue: 'e9a507'
    },
    {
        colorName: 'htmlThemeReset',
        colorHashValue: 'ffffff'
    }
]

const apiConfigPath = () => {
    const currentUrl = window.location.href
    if (currentUrl.includes('pdfsimpliwizard.local')) {
        return '/data'
    } else {
        return 'https://worksimpli-forms-templates.s3.us-east-2.amazonaws.com/data'
    }
}

const apiConf: {[key: string]:string} = {
    developmentBaseUrlForObject: apiConfigPath()
}

enum jsonKeyMapping {
    SLUG = 'title', // where to get slug string
    NAV_TITLE = 'navTitle', // where to get slug string
    SLUGIFY = 'slugify', // Where to set slug string
    STEPS = 'steps', // Where to get steps
    HAS_NEXT= 'hasNext', // Button for next
    GROUP= 'group', // For Grouping the navigation
    HAS_PREVIOUS = 'hasPrevious', // Button for previous
    HAS_SUBMIT = 'hasSave', // Button for submit
    FORM_GROUP = 'formGroup', // json enum for 'formGroup'
    FORM_LABEL = 'label', // json enum for 'formGroup - label'
    FORM_VALUE = 'value', // json enum for 'formGroup - value'
    FORM_TEXT = 'text', // json enum for 'formGroup - text'
    FORM_NAME = 'name', // json enum for 'formGroup - value'
    FORM_ID = 'id', // json enum for 'formGroup - id'
    FORM_TYPE = 'type', // json enum for 'formGroup - type'
    FORM_CLASS = 'class', // json enum for 'formGroup - type'
    FORM_PLACEHOLDER = 'placeholder', // json enum for 'formGroup - placeholder'
    FORM_MAX_LENGTH = 'max_length', // json enum for 'formGroup - max_length'
    FORM_SUB_TOOLTIP = 'subtooltip', // json enum for 'formGroup - subtooltip'
    FORM_INPUT_RADIO_MORE_FIELDS = 'morefields', // json enum for 'formGroup - inputRadio - morefields',
    FORM_ADD_MORE= 'addmore', // json enum for 'formGroup - addmore'
    FORM_ADD_MORE_LABEL= 'addmoreLabel', // json enum for 'formGroup - addmoreTitle'
    FORM_SECOND_LEVEL= 'secondLevel', // json enum for 'formGroup - secondLevel'
    FORM_SUB_LABEL= 'subLabel', // json enum for 'formGroup - subLabel'
    FORM_IS_MULTIPLE_ADD_YOUR_OWN= 'isMultipleAddYourOwn', // json enum for 'formGroup - isMultipleAddYourOwn'
    FORM_PREFIX_SYMBOL= 'prefixSymbol', // json enum for 'formGroup - prefixSymbol'
    FORM_SUFFIX_SYMBOL= 'suffixSymbol', // json enum for 'formGroup - suffixSymbol'
    FORM_DATE_FORMAT= 'dateFormat', // json enum for 'formGroup - dateFormat'
    FORM_DATE_TIME_FORMAT= 'dateTimeFormat', // json enum for 'formGroup - dateFormat'
    FORM_VALIDATION_REGEX= 'validationRegex', // json enum for 'formGroup - validationRegex'
    FORM_MAX_LIMIT= 'maxLimit', // json enum for 'formGroup - maxLimit'
    DESCRIPTION = 'description', // json enum for 'description'
    TITLE_SECOND = 'titleSecond', // json enum for 'titleSecond'
    TOOLTIP = 'tooltip', // json enum for 'tooltip'
    FAQ = 'faq', // json enum for 'faq'
    FAQ_TEXT = 'Text', // json enum for 'faq'
    FAQ_HEADER = 'header', // json enum for 'faq'
    STEPS_BOX_LEFT = 'stepsBoxLeft', // json enum for 'stepsBoxLeft',
    FORM_INPUT_SEPARATOR ='inputSeparator', // json enum for 'inputSeparator',
    FORM_GROUP_INPUT_SEPARATOR ='groupInputSeparator', // json enum for 'groupInputSeparator',
    /* ADD STEP */
    PARENT='parent',
    SHOW_ON_FORM_ID='showOnFormId',
    SHOW_ON_FORM_VALUE='showOnFormValue',
    IS_GROUP_ADD_DYNAMIC ='isGroupAddDynamic',
    /* Add Group of Elements */
    FORM_ADD_MORE_GROUP = 'addMoreGroup',
    FORM_GROUP_ELEMENT = 'groupElement',
    FORM_ADD_MORE_GROUP_LABEL = 'addmoreGroupLabel',
    FORM_ADD_MORE_SUB_TOOL_TIP = 'addmoreSubtooltip'
}

enum templateActionEnum {
    SET_TEMPLATE_PATH ='SET_TEMPLATE_PATH',
    LOAD_TEMPLATE ='LOAD_TEMPLATE',
    SET_TEMPLATE ='SET_TEMPLATE',
    UPDATE_TEMPLATE_STEP ='UPDATE_TEMPLATE_STEP',
    SET_IS_LOADING ='SET_IS_LOADING',
    SET_MODAL_STATE ='SET_MODAL_STATE',
    SET_ERROR ='SET_ERROR',
    SET_SELECTED_STATE ='SET_SELECTED_STATE',
    SET_ACTIVE_TEMPLATE_HTML='SET_ACTIVE_TEMPLATE_HTML',
    SET_ACTIVE_TEMPLATE_STEPS='SET_ACTIVE_TEMPLATE_STEPS',
    SET_CONDITIONAL_STEPS='SET_CONDITIONAL_STEPS',
    SET_ACTIVE_TEMPLATE_GROUP_BY='SET_ACTIVE_TEMPLATE_GROUP_BY',
    UPDATE_ACTIVE_TEMPLATE_STEP='UPDATE_ACTIVE_TEMPLATE_STEP',
    SET_NAVBAR_STATE='SET_NAVBAR_STATE',
    SET_STEP_FLAG='SET_STEP_FLAG',
    SET_NO_TEMPLATE_FOUND= 'SET_NO_TEMPLATE_FOUND',
    SET_ACTIVE_GROUP='SET_ACTIVE_GROUP',
    RESET ='RESET',
}

enum formActionEnum {
    SET_COLOR ='SET_COLOR',
    SET_FORM_DATA ='SET_FORM_DATA',
    RESET_FORM_DATA ='RESET_FORM_DATA',
    RESET_STEP_DATA ='RESET_STEP_DATA',
    SET_STEP_DATA ='SET_STEP_DATA',
    REMOVE_STEP_DATA ='REMOVE_STEP_DATA',
    REMOVE_FORM_DATA ='REMOVE_FORM_DATA',
    SET_BLANK_FORM_DATA ='SET_BLANK_FORM_DATA',
    RESET_BLANK_FORM_DATA ='RESET_BLANK_FORM_DATA',
    SET_DOC_ID ='SET_DOC_ID',
    SET_PARAGRAPH ='SET_PARAGRAPH',
    RESET ='RESET'
}

const TOAST_AUTO_HIDE_AFTER = 5000

const NO_GROUP = 'NoGroup'

const DEFAULT_VALUE = 'NonEmpty'
const DEFAULT_RADIO_VALUE = 'defaultRadio'
const DEFAULT_VALUE_SELECT = 'Please Select'
const DOCUMENT_PAPER_TYPE = ['letter', 'legal']

enum sessionStorageKey {
    TEMPLATE_STATE = 'templateState',
    FORM_STATE = 'formState',
    SELECTED_STATE='state'
}

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'
const DOC_STAGE = 'wizard'
const TYPE_OF_DOC = 40
const ORDER = 0
const SECTION_CD = 0
const ROOT_PATH = 'app'

export {
    colors,
    apiConf,
    jsonKeyMapping,
    templateActionEnum,
    formActionEnum,
    TOAST_AUTO_HIDE_AFTER,
    sessionStorageKey,
    NO_GROUP,
    DEFAULT_VALUE,
    DEFAULT_RADIO_VALUE,
    DEFAULT_VALUE_SELECT,
    DOCUMENT_PAPER_TYPE,
    EMPTY_GUID,
    DOC_STAGE,
    TYPE_OF_DOC,
    ORDER,
    SECTION_CD,
    ROOT_PATH
}
