import axios from 'axios'
import { useContext, useEffect } from 'react'
import { templateActionEnum } from '../Config/Constants'
import TemplateDataContext from '../Contexts/TemplateData.context'
import ErrorMessage from '../Util/ErrorMessage'
import { loadTemplate, modifiedTemplateJson, skipFirstStepJson } from '../Util/TemplateFunction'

type useLoadJsonType = {
    endPoint:string,
    jsonFileName:string,
    isModified?:boolean,
    state?:string
}

export default async function useLoadJson ({ endPoint, jsonFileName, isModified = true, state } : useLoadJsonType): Promise<void> {
    const { state: templateState, dispatch } = useContext(TemplateDataContext)
    /* LOAD THE TEMPLATE  */
    useEffect(() => {
        dispatch({ type: templateActionEnum.SET_NO_TEMPLATE_FOUND, payload: false })
        const cancelToken = axios.CancelToken.source()
        const fetchTemplates = async () => {
            dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: true })
            try {
                const config = { cancelToken: cancelToken.token }
                dispatch({ type: templateActionEnum.SET_TEMPLATE_PATH, payload: endPoint })
                const templateResult = await loadTemplate({ endPoint, jsonFileName, config })
                const { data } = templateResult
                const filterTemplate:any = {}

                for (const key in data) {
                    if (data[key].steps) {
                        filterTemplate[key] = data[key]
                    }
                }

                let templates = filterTemplate
                if (isModified) templates = modifiedTemplateJson(filterTemplate)
                if (state) templates = skipFirstStepJson(templates)
                dispatch({ type: templateActionEnum.LOAD_TEMPLATE, payload: templates })
            } catch (error) {
                if (!axios.isCancel(error)) {
                    dispatch({ type: templateActionEnum.SET_NO_TEMPLATE_FOUND, payload: true })
                    dispatch({ type: templateActionEnum.SET_ERROR, payload: { show: true, message: ErrorMessage.TEMPLATE_FAILED } })
                }
                dispatch({ type: templateActionEnum.SET_TEMPLATE, payload: {} })
            } finally {
                dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: false })
            }
        }
        if (templateState.templateKeys.length === 0) fetchTemplates()
        return () => cancelToken.cancel()
    }, [dispatch, endPoint, isModified, jsonFileName, state])
}
