import React, { useContext, useEffect, useState } from 'react'
import { NavDropdown } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { jsonKeyMapping, templateActionEnum } from '../../../Config/Constants'
import TemplateDataContext from '../../../Contexts/TemplateData.context'
import { TemplateObject } from '../../../Models/TemplateObject'
import DropDownItems from './DropDownItems'
import NavDropdownTitle from './NavDropdownTitle'

type Props = {
    groupStepList: TemplateObject,
    groupName: string,
    uri: string
}

const DropDownNavbar = ({ groupStepList, groupName, uri }: Props) => {
    const { stepName } = useParams()
    const [isVisited, setIsVisited] = useState(false)
    const { state, dispatch } = useContext(TemplateDataContext)
    const { activeTemplateGroupBy, activeTemplateSteps, activeGroup } = state

    /* check if the current group is active  */
    useEffect(() => {
        /* Find the active step from step list */
        const isPresent = activeTemplateSteps.find((step: TemplateObject) => (stepName === step[jsonKeyMapping.SLUGIFY]))
        if (isPresent &&
            isPresent[jsonKeyMapping.GROUP] &&
            isPresent[jsonKeyMapping.GROUP].toLowerCase() === groupName.toLowerCase()
        ) {
            dispatch({ type: templateActionEnum.SET_ACTIVE_GROUP, payload: isPresent[jsonKeyMapping.GROUP] })
        }

        if (isPresent && isPresent[jsonKeyMapping.GROUP] === undefined) {
            dispatch({ type: templateActionEnum.SET_ACTIVE_GROUP, payload: '' })
        }

        return () => { }
    }, [stepName, groupName, uri, groupStepList])

    /* check if the current group is visited */
    useEffect(() => {
        /* compare group step with active step */
        const currentStep = activeTemplateSteps.find((step: TemplateObject) => (stepName === step[jsonKeyMapping.SLUGIFY]))
        if (currentStep && currentStep[jsonKeyMapping.GROUP] !== undefined) {
            const allGroup:string[] = []
            activeTemplateGroupBy.forEach((step: TemplateObject) => {
                if (step.groupName !== undefined) {
                    allGroup.push(step.groupName.toLowerCase())
                }
            })
            const groupIndex = allGroup.findIndex(group => (group.toLowerCase() === groupName.toLowerCase()))
            const currentGroupIndex = allGroup.findIndex(group => (group.toLowerCase() === currentStep[jsonKeyMapping.GROUP].toLowerCase()))
            if (currentGroupIndex > groupIndex) {
                setIsVisited(true)
            } else {
                setIsVisited(false)
            }
        }
        /* compare non-group step with active step */
        if (currentStep && currentStep[jsonKeyMapping.GROUP] === undefined) {
            const groupIndex = activeTemplateGroupBy.findIndex((step: TemplateObject) => (step.groupName && step.groupName.toLowerCase() === groupName.toLowerCase()))
            const currentStepIndex = activeTemplateGroupBy.findIndex((step: TemplateObject) => (stepName === step[jsonKeyMapping.SLUGIFY]))
            if (currentStepIndex > groupIndex) {
                setIsVisited(true)
            } else {
                setIsVisited(false)
            }
        }

        return () => { }
    }, [stepName, groupName, uri, groupStepList])

    /* onclick handler dropdown menu */
    const toggleHandler = (groupName: string) => {
        if (groupName.toLowerCase() === activeGroup.toLowerCase()) {
            dispatch({ type: templateActionEnum.SET_ACTIVE_GROUP, payload: '' })
            return
        }
        dispatch({ type: templateActionEnum.SET_ACTIVE_GROUP, payload: groupName })
    }

    return (
        <NavDropdown
            title={<NavDropdownTitle title={groupName} dropDownState={groupName.toLowerCase() === activeGroup.toLowerCase()} />}
            id="navbarScrollingDropdown"
            className={`list-group-item body-font3 nav-item dropdown ${groupName.toLowerCase() === activeGroup.toLowerCase() && 'active fw-700'} ${isVisited && 'grey-color fw-700'}`}
            key={groupName}
            onToggle={() => toggleHandler(groupName)}
            show={groupName.toLowerCase() === activeGroup.toLowerCase()}
            autoClose={false}
        >
            <DropDownItems uri={uri} steps={groupStepList[groupName]} />
        </NavDropdown>
    )
}

export default DropDownNavbar
