import React, { useContext, useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import LoaderComponent from '../../Components/Shared/LoaderComponent'
import ChooseTemplate from '../../Components/Template/ChooseTemplate'
import NoTemplate from '../../Components/Template/NoTemplate'
import { formActionEnum, templateActionEnum, colors } from '../../Config/Constants'
import FormDataContext from '../../Contexts/FormData.context'
import TemplateDataContext from '../../Contexts/TemplateData.context'
import useLoadJson from '../../Hooks/LoadJson'

const LoadTemplate = () => {
    const { state: templateState, dispatch } = useContext(TemplateDataContext)
    const { templateType, state } = useParams()
    const { dispatch: formDispatch } = useContext(FormDataContext)

    useEffect(() => {
        if (state) {
            dispatch({ type: templateActionEnum.SET_SELECTED_STATE, payload: state })
        } else {
            dispatch({ type: templateActionEnum.SET_SELECTED_STATE, payload: '' })
        }
        return () => {}
    }, [state])

    if (state) { /* Load Json */
        useLoadJson({
            endPoint: `state-specific/${templateType}`,
            jsonFileName: `${_.capitalize(state)}.json`,
            state: _.capitalize(state)
        })
    } else {
        useLoadJson({
            endPoint: templateType || '',
            jsonFileName: `${templateType}.json`
        })
    }

    /* Reset Previous Value */
    useEffect(() => {
        formDispatch({ type: formActionEnum.RESET_FORM_DATA })
        formDispatch({ type: formActionEnum.RESET_STEP_DATA })
        formDispatch({ type: formActionEnum.SET_COLOR, payload: colors[0] })
        formDispatch({ type: formActionEnum.SET_DOC_ID, payload: '' })
        formDispatch({ type: formActionEnum.SET_PARAGRAPH, payload: {} })
        formDispatch({ type: formActionEnum.RESET_BLANK_FORM_DATA })
        dispatch({ type: templateActionEnum.SET_STEP_FLAG, payload: false })
        dispatch({ type: templateActionEnum.SET_NAVBAR_STATE, payload: false })
        return () => {}
    }, [templateType])

    if (templateState.isLoading) return (<LoaderComponent/>)

    if (templateState.noTemplateFound) return (<NoTemplate/>)

    return (
        <ChooseTemplate />
    )
}

export default LoadTemplate
