import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import StateSpecificDropDown from '../../Components/Shared/FormInputs/StateSpecificDropDown'
import TemplateDataContext from '../../Contexts/TemplateData.context'
import ButtonComponent from '../../Components/Shared/ButtonComponent'
import { ROOT_PATH, sessionStorageKey, templateActionEnum } from '../../Config/Constants'
import LoaderComponent from '../../Components/Shared/LoaderComponent'
import axios from 'axios'
import { loadTemplate } from '../../Util/TemplateFunction'

const StateSpecific = () => {
    const { state, dispatch } = useContext(TemplateDataContext)
    const { templates } = state
    const { pathname } = useLocation()
    const [options, setOptions] = useState<{[key: string]:string}[]>([])
    const [option, setOption] = useState<string>('')
    const navigate = useNavigate()

    const fetchState = async () => {
        const endPoint = `${pathname.replace(`/${ROOT_PATH}/`, '')}`
        const jsonFileName = 'state-specific.json'
        const cancelToken = axios.CancelToken.source()
        const config = { cancelToken: cancelToken.token }

        const templateResult = await loadTemplate({ endPoint, jsonFileName, config })
        const { data } = templateResult

        dispatch({ type: templateActionEnum.LOAD_TEMPLATE, payload: data })
        dispatch({ type: templateActionEnum.SET_TEMPLATE_PATH, payload: endPoint })
    }

    useEffect(() => {
        fetchState()
    }, [])

    /* Set State Specific Options */
    useEffect(() => {
        const tempOptions = [
            {
                value: '',
                text: '--- Select State ---'
            }
        ]
        /* @ts-ignore */
        for (const key in templates.state) {
            const state = key.replace(/-/g, ' ')
            tempOptions.push({
                value: templates.state[key],
                text: state
            })
        }
        setOptions(tempOptions)
        return () => { }
    }, [templates])

    const chooseTemplate = () => {
        const state = option.replace(/-/, '')
        if (state === '') return false

        const template = JSON.parse(`${sessionStorage.getItem(sessionStorageKey.TEMPLATE_STATE)}`)
        let endPoint
        if (template) {
            // @ts-ignore: Object is possibly 'null'.
            endPoint = `${template.templatePath}/${state}`
        } else {
            endPoint = '/'
        }

        dispatch({ type: templateActionEnum.SET_SELECTED_STATE, payload: option })
        dispatch({ type: templateActionEnum.LOAD_TEMPLATE, payload: [] })
        navigate(`/${ROOT_PATH}/` + endPoint)
    }

    if (state.isLoading) return (<LoaderComponent />)

    return (
        <div className='row justify-content-center align-content-center rowgutter'>
            <div id="steps-form" className="col-lg-6 px-3">
                <div className="card-header headerheight">
                </div>
                <div className='card step_id'>
                    <div className="col-md-12">
                        <div className="font-22 fw-600 blue-color text-center p-3">
                            <h4>{templates.title || ''}</h4>
                        </div>
                        <p className='text-center m-2'>{(templates.subTitle || '')}</p>
                    </div>
                    <div className="col-md-12 p-2">
                        <StateSpecificDropDown
                            id={'StateSpecificState'}
                            setOption={setOption}
                            options={{
                                label: 'State',
                                type: 'select',
                                id: 'StateSpecificState',
                                inputClass: 'col-md-12',
                                options
                            }} />
                    </div>
                </div>
                <div className="col-md-12 d-flex justify-content-center align-items-center mt-4 mb-2rem">
                    <ButtonComponent buttonProps={{ className: 'btn primary-button btn-orange', id: 'nextBtn', style: {}, text: 'NEXT' }} clickHandler={chooseTemplate} />
                </div>
            </div>
        </div>
    )
}

export default StateSpecific
