import React, { useContext, useEffect } from 'react'
import FormDataContext from '../../Contexts/FormData.context'
import InputCheckbox from '../Shared/FormInputs/InputCheckbox'
import InputDate from '../Shared/FormInputs/InputDate'
import InputDateTime from '../Shared/FormInputs/InputDateTime'
import InputRadio from '../Shared/FormInputs/InputRadio'
import InputSelect from '../Shared/FormInputs/InputSelect'
import InputText from '../Shared/FormInputs/InputText'
import InputTextArea from '../Shared/FormInputs/InputTextArea'
import { DEFAULT_VALUE, DEFAULT_VALUE_SELECT, formActionEnum, jsonKeyMapping } from '../../Config/Constants'
import * as _ from 'lodash'
import { DYNAMIC_INSERT_FLAG } from '../../environment'
import { TemplateObject } from '../../Models/TemplateObject'
import InputInformation from '../Shared/FormInputs/InputInformation'
import { InputContextProvider } from '../../Contexts/Input.Context'
import { useParams } from 'react-router-dom'
type Props = {
  formGroup: TemplateObject;
  hasParent?: TemplateObject; // Form Element with parent Form Element
  stepDetails?: TemplateObject; // Step Details
  fieldIndex?:any
  groups?:any
};

const FormBuilder = ({ formGroup, hasParent, stepDetails, fieldIndex, groups }: Props) => {
    const { state, dispatch } = useContext(FormDataContext)
    const { formData } = state
    const { stepName } = useParams()

    const setFormContextData = (id: string, value: any, inputValue?:string) => {
        // Save elementId and Value in FormData Context
        const formElementData: TemplateObject = {
            [id]: value
        }

        /* Input Radio/Input Check changes */
        if (inputValue && _.has(formData, `meta-data-${id}`)) {
            const selectedValues = formData[`meta-data-${id}`].values.map((id:string) => {
                /* CHANGE FROM LABEL TO VALUE */
                if (id === value) {
                    return {
                        id,
                        style: 'inline-block'
                    }
                }
                return {
                    id,
                    style: 'none'
                }
            })
            formElementData[`meta-data-${id}`] = {
                ...formData[`meta-data-${id}`],
                selectedValues
            }
        }

        /* METADATA ADD FOR : FORM ELEMENT WITH CHILDREN FORM ELEMENTS/SPECIAL CASE */
        if (hasParent) {
            const key = `meta-data-${id}`
            if (_.has(formData, key)) {
                const selectedValues = formElementData[key] || {}
                formElementData[key] = { ...formData[key], ...selectedValues, ...hasParent }
            } else {
                formElementData[key] = hasParent
            }
        }
        dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })
    }

    /* REMOVE PREVIOUS SELECTED VALUES BASED SELECTED VALUES */
    useEffect(() => {
        const keys = []
        for (const key in formData) {
            if (key.match('meta-data-')) {
                // eslint-disable-next-line no-unused-vars
                const [_key, elementID] = key.split('meta-data-')
                const metaDataObject = formData[key]
                const id = metaDataObject.id
                const value = metaDataObject.label
                const showElement = metaDataObject.showElement
                const checkboxAddYour = metaDataObject.checkboxAddYour
                const separator = metaDataObject.separator

                /* Checkbox : Add Your Own */
                if (checkboxAddYour === true) {
                    const checkboxAddYourParent = formData[`meta-data-${id}`]?.checkboxAddYour
                    /* skip don't depend input text */
                    if (checkboxAddYourParent === true) continue
                    if (checkboxAddYourParent === false) {
                        keys.push(key)
                        keys.push(elementID)
                    }
                }

                /* Array Data type case handled */
                if (formData[id] !== undefined &&
                    ((formData[id].constructor.name === 'Array' && !formData[id].includes(value)) ||
                    (formData[id].constructor.name === 'String' && formData[id] !== value))
                ) {
                    /* CONDITION ADDED FOR Input text,textarea,date and datetime */
                    if (
                        (formData[id] !== '' && showElement === DEFAULT_VALUE) ||
                        (formData[id] !== '' && showElement === formData[id]) ||
                        (separator)
                    ) {
                        break
                    }
                    /* REMOVE ADD MORE VALUES OF CONDITIONAL BASED INPUT */
                    let i = 0
                    const addMoreKeys = []
                    while (formData[`${elementID}${i}`]) {
                        addMoreKeys.push(`${elementID}${i}`)
                        i++
                    }
                    keys.push(key)
                    keys.push(elementID)
                    keys.push(...addMoreKeys)
                }

                /* delete children if its parent is removed */
                if (keys.includes(id)) {
                    keys.push(key)
                    keys.push(elementID)
                }
            }
        }
        if (keys.length > 0) {
            dispatch({ type: formActionEnum.REMOVE_FORM_DATA, payload: keys })
        }

        return () => {}
    }, [formData])

    // Input Change Event Handler
    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const inputId = event.target.id
        const inputValue = event.target.value
        setFormContextData(inputId, inputValue)
    }

    // TextArea Event Handler
    const handleTextAreaChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const inputId = event.target.id
        const inputValue = event.target.value
        setFormContextData(inputId, inputValue)
    }

    // Input Selection Change Event Handler
    const handleSelectInput = (event: TemplateObject, inputId: string) => {
        let inputValue = event.text
        if (inputValue === DEFAULT_VALUE_SELECT) {
            inputValue = ''
        }
        setFormContextData(inputId, inputValue)
    }

    // Input Radio Click Event Handler
    const handleRadioInput = (inputId: string, label: string, value?: string) => {
        /* CHANGE FROM LABEL TO VALUE */
        setFormContextData(inputId, value, label)
    }

    // Input Checkbox Event Handler
    const handleCheckBoxSelect = (
        inputId: string,
        label: string,
        event: React.ChangeEvent<HTMLInputElement>,
        dynamicInsertFlag: number
    ) => {
        const inputValue = formData[inputId] ? [...formData[inputId]] : []
        if (dynamicInsertFlag === DYNAMIC_INSERT_FLAG.STATIC) {
            if (event.target.checked && !inputValue.includes(label)) {
                inputValue.push(label)
            } else {
                _.pull(inputValue, label)
            }
        } else {
            if (dynamicInsertFlag === DYNAMIC_INSERT_FLAG.DYNAMIC_INSERT) {
                inputValue.push(label)
            } else {
                _.pull(inputValue, label)
            }
        }
        setFormContextData(inputId, inputValue)
    }

    const onBlur = (e:any, label:any, formElement:any) => {
        const isSingleAddMoreParent = formElement.addmore === 'yes'
        const isSingleAddMoreChild = formElement.addmore === false
        const allFormData = { ...formData }
        const index = e.target.title
        const inputLabel = label.replaceAll(' ', '')
        const currentStep = stepName || ''
        const inputValue = e.target.value
        const groupLabel:string[] = []
        const parentLabelArray:string[] = []
        let findParentLabel = ''

        groups && groups.forEach((item:any) => {
            if (item.addMoreGroup === 'yes') {
                if (item.groupElement) {
                    item.groupElement.forEach((group:any) => {
                        const inputLabel = group.label.replaceAll(' ', '')
                        groupLabel.push(inputLabel)
                        if (group.label === label) {
                            parentLabelArray.push(...item.groupElement)
                        }
                    })
                }
            }
        })

        if (parentLabelArray.length > 0) {
            const lastIndex:any = parentLabelArray[parentLabelArray.length - 1]

            if (lastIndex && lastIndex.label) {
                findParentLabel = lastIndex.label.replaceAll(' ', '')
            }
        }

        if (isSingleAddMoreParent || isSingleAddMoreChild) {
            const parentLabel = currentStep + '-' + inputLabel

            if (allFormData[parentLabel] === undefined) {
                allFormData[parentLabel] = [{ [inputLabel]: inputValue }]
            }

            if (allFormData[parentLabel]) {
                const updateData = [...allFormData[parentLabel]]
                const findIndex = allFormData[parentLabel].findIndex((item:object, i:number) => i === parseInt(index))

                if (findIndex !== -1) {
                    updateData[index] = {
                        ...updateData[index],
                        [inputLabel]: inputValue
                    }
                } else {
                    updateData.push({
                        [inputLabel]: inputValue
                    })
                }

                allFormData[parentLabel] = updateData
            }

            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: allFormData })
        }

        if (groupLabel.includes(inputLabel)) {
            const parentLabel = currentStep + '-' + findParentLabel

            if (allFormData[parentLabel] === undefined) {
                allFormData[parentLabel] = [{ [inputLabel]: inputValue }]
            }

            if (allFormData[parentLabel]) {
                const updateData = [...allFormData[parentLabel]]
                const findIndex = allFormData[parentLabel].findIndex((item:object, i:number) => i === parseInt(index))

                if (findIndex !== -1) {
                    updateData[index] = {
                        ...updateData[index],
                        [inputLabel]: inputValue
                    }
                } else {
                    updateData.push({
                        [inputLabel]: inputValue
                    })
                }

                allFormData[parentLabel] = updateData
            }

            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: allFormData })
        }
    }

    // Get components according to formElement data
    const formDataViews = formGroup.map((formElement: TemplateObject, index: number) => {
        let jsxData = null
        switch (formElement[jsonKeyMapping.FORM_TYPE]) {
        case 'text':
            jsxData = (<InputText fieldIndex={fieldIndex} formElement={formElement} handleInputChange={handleInputChange} onBlur={onBlur} groups={groups} />)
            break
        case 'textarea':
            jsxData = (<InputTextArea formElement={formElement} handleTextAreaChange={handleTextAreaChange} />)
            break
        case 'select':
            jsxData = (<InputSelect formElement={formElement} handleSelectInput={handleSelectInput} />)
            break
        case 'date':
            jsxData = (<InputDate formElement={formElement} handleInputChange={handleInputChange} />)
            break
        case 'datetime':
            jsxData = (<InputDateTime formElement={formElement} handleInputChange={handleInputChange} />)
            break
        case 'radio':
            jsxData = (<InputRadio formElement={formElement} stepDetail={stepDetails || {}} handleRadioInput={handleRadioInput} />)
            break
        case 'checkbox':
            jsxData = (<InputCheckbox formElement={formElement} handleCheckBoxSelect={handleCheckBoxSelect} />)
            break
        case 'information':
            jsxData = (<InputInformation formElement={formElement} />)
            break
        default:
            jsxData = <></>
            break
        }
        return <InputContextProvider key={index} >{jsxData}</InputContextProvider>
    })

    return <>{formDataViews}</>
}

export default FormBuilder
