import React, { ReactElement } from 'react'

type Props = {
    subText: string,
    clickHandler?: React.MouseEventHandler<HTMLDivElement>
}

export default function StepBoxLeft ({ subText, clickHandler }: Props): ReactElement {
    return (
        <div onClick={clickHandler || undefined}>
            <div className='row justify-content-md-center text-center'>
                <div className='steps-box col-md-6 col-lg-4'>{subText || ''}</div>
            </div>
        </div>
    )
}
