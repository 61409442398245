import axios from 'axios'
import { templateActionEnum } from '../Config/Constants'

const htmlToSvg = (formData: any, setSvgData: any, dispatch: any) => {
    dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: true })

    axios({
        method: 'post',
        url: 'http://html2svg.mowebapp.com/Home/HtmlString2Svg',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(function (response) {
            // handle success
            if (response.status === 200) {
                if (response.data && response.data.images) {
                    setSvgData(response.data.images)
                }
            }
            dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: false })
        })
        .catch(function (response) {
            // handle error
            dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: false })
        })
}

export { htmlToSvg }
