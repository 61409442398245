import React from 'react'
import { Link } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { BsXLg } from 'react-icons/bs'

type Props = {
    children?: React.ReactNode,
    handleShow: React.MouseEventHandler
    handleClose: React.MouseEventHandler,
    isOpen:boolean
}

const TemplateHeader = ({ children, handleShow, handleClose, isOpen }: Props) => {
    return (
        <div className="d-flex">
            <Link to='/' className="displaymobile">
                <img src="/images/PDFSimpli-logo.svg" className="height-37 mt-3 mb-3 ps-20" />
            </Link>
            <nav className="navbar navbar-expand-lg navbar-light mx-2 pointer">
                <div className="navbar-toggler border-0 ml-3" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    { isOpen ? <BsXLg size={24} onClick={handleClose} /> : <FaBars size={24} onClick={handleShow} /> }
                </div>
            </nav>
            <div className="displaylogo">
                <Link to='/'>
                    <img src="/images/PDFSimpli-logo.svg" className="height-26 mt-3 mb-3 me-40" />
                </Link>
            </div>
            <div className="overlay" style={{ display: 'none' }}></div>
        </div>
    )
}

export default TemplateHeader
