import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EMPTY_GUID, ORDER, ROOT_PATH, formActionEnum, jsonKeyMapping, templateActionEnum } from '../../Config/Constants'
import TemplateDataContext from '../../Contexts/TemplateData.context'
import useLoadJson from '../../Hooks/LoadJson'
import useNavigateRoutes from '../../Hooks/NavigateRoutes'
import { logger } from '../../Middleware/Logger'
import ModalComponent from '../Shared/ModalComponent'
import FormPreview from './TemplateDesign/FormPreview'
import MainForm from './TemplateDesign/MainForm'
import SideNavbar from './TemplateDesign/SideNavbar'
import FormDataContext from '../../Contexts/FormData.context'
import { createDoc, createParagraphService, getPdfSimpliDoc, updateParagraphService } from '../../Services/TemplateServices'
import { createCookie, createParagraph, emptyDocument, filterParagraph, getDocIds, readCookie, saveDocIds } from '../../Util/helper'
import { v4 as UUID4 } from 'uuid'

const SetTemplateDesign = () => {
    let { templateType, template, stepName, state } = useParams()
    const navigate = useNavigate()

    const formData = useContext(FormDataContext)
    const docId :string = formData.state?.docId as string
    const paragraph = formData.state?.paragraph
    const formValue :object = formData.state?.formData
    const visitorGuid :string = readCookie('visitorGuid') as string
    const templateID :string = templateType as string
    const templateNum :string = template as string
    const templateNumber :string = templateNum.substr(templateNum.length - 1)

    stepName = stepName?.toLowerCase()
    const { state: templateState, dispatch } = useContext(TemplateDataContext)
    const { templates, showTemplateModal, activeTemplateSteps } = templateState
    const [stepDetails, setStepDetails] = useState({})
    const [isHideSteps, setIsHideSteps] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [filterFormValue, setFilterFormValue] = useState({})

    useEffect(() => {
        const hideSteps :string[] = []

        activeTemplateSteps.length > 0 && activeTemplateSteps.forEach((item:any) => {
            if (item.formGroup && item.formGroup.length === 0) {
                hideSteps.push(item.slugify)
            }
        })
        setIsHideSteps(hideSteps)
    }, [templateState])

    useEffect(() => {
        const filterFormKey:object = { ...formValue }

        // remove meta-data keys
        for (const key in filterFormKey) {
            if (key.includes('meta-data-')) {
                delete filterFormKey[key as keyof object]
            }
        }

        setFilterFormValue(filterFormKey)
    }, [formValue])

    useEffect(() => {
        if (state) dispatch({ type: templateActionEnum.SET_SELECTED_STATE, payload: state })
        return () => { }
    }, [state])

    if (state) {
        /* Load Json */
        useLoadJson({
            endPoint: `state-specific/${templateType}`,
            jsonFileName: `${_.capitalize(state)}.json`,
            state: _.capitalize(state)
        })

        // all the sub routes will change from this
        useNavigateRoutes({
            templates,
            setStepDetails,
            state
        })
    } else {
        /* Load Json */
        useLoadJson({
            endPoint: templateType || '',
            jsonFileName: `${templateType}.json`
        })

        // all the sub routes will change from this
        useNavigateRoutes({
            templates,
            setStepDetails
        })
    }

    useEffect(() => {
        handleDocId()

        const visitorId = UUID4()
        if (visitorGuid === null || visitorGuid === '') {
            createCookie('visitorGuid', visitorId, 1)
        }
    }, [])

    // handleDocId function is find the template docId and update the form value
    const handleDocId = async () => {
        const getDocId = await getDocIds()
        if (getDocId) {
            let docId = ''
            for (const key in getDocId) {
                if (key.includes(templateID)) {
                    docId = getDocId[templateID]
                }
            }

            if (docId !== '') {
                const getDocuments = await getPdfSimpliDoc(docId)
                if (getDocuments.data.paragraphs.length > 0) {
                    const paragraph = getDocuments.data.paragraphs
                    const para = filterParagraph(paragraph)
                    const storeParaResponse = { paraId: para.paraId, paraContentId: para.paraId }

                    formData.dispatch({ type: formActionEnum.SET_DOC_ID, payload: docId })
                    formData.dispatch({ type: formActionEnum.SET_PARAGRAPH, payload: storeParaResponse })
                    if (Object.keys(para.content).length > 0) {
                        const paraKeys :any = para.content
                        formData.dispatch({ type: formActionEnum.SET_FORM_DATA, payload: paraKeys })
                    }
                }
            }
        }
    }

    const nextBtnHandler = async () => {
        try {
            if (template !== undefined) {
                const existingStepIndex = _.findIndex(activeTemplateSteps, { [jsonKeyMapping.SLUGIFY]: stepName })
                if (existingStepIndex > -1 && activeTemplateSteps.length > existingStepIndex) {
                    setIsLoading(true)
                    const nextSlugify = activeTemplateSteps[existingStepIndex + 1][jsonKeyMapping.SLUGIFY]

                    if (docId === '') {
                        const data = emptyDocument(visitorGuid, templateID, templateNumber, state)

                        // create document ID
                        const docResponse = await createDoc(data)
                        const para = createParagraph(EMPTY_GUID, docResponse.data.docGuid, JSON.stringify(filterFormValue), ORDER, templateID, EMPTY_GUID)

                        // create paragraph
                        const paraResponse = await createParagraphService(docResponse.data.docGuid, para)
                        const storeParaResponse = { paraId: paraResponse.data.id, paraContentId: paraResponse.data.paraContents[0].id }

                        // store and navigate
                        formData.dispatch({ type: formActionEnum.SET_DOC_ID, payload: docResponse.data.docGuid })
                        formData.dispatch({ type: formActionEnum.SET_PARAGRAPH, payload: storeParaResponse })
                        saveDocIds(templateID, docResponse.data.docGuid)
                        setIsLoading(false)
                        if (state) {
                            navigate(`/${ROOT_PATH}/state-specific/${templateType}/${state}/${template}/${nextSlugify}`)
                        } else {
                            navigate(`/${ROOT_PATH}/${templateType}/${template}/${nextSlugify}`)
                        }
                    } else {
                        const updatePara = createParagraph(paragraph.paraId, docId, JSON.stringify(filterFormValue), ORDER, templateID, paragraph.paraContentId)
                        await updateParagraphService(docId, paragraph.paraId, updatePara)
                        setIsLoading(false)
                        if (state) {
                            navigate(`/${ROOT_PATH}/state-specific/${templateType}/${state}/${template}/${nextSlugify}`)
                        } else {
                            navigate(`/${ROOT_PATH}/${templateType}/${template}/${nextSlugify}`)
                        }
                    }
                }
            }

            // setStepsCompleted([...stepsCompleted, stepNumber])
        } catch (error) {
            logger.error('🚀 ~ file: SetTemplate.tsx ~ line 37 ~ nextBtnHandler ~ error', error)
        }
    }

    const backBtnHandler = () => {
        navigate(-1)
    }

    const step : string = stepName as string

    return (
        <div className="row">
            {/* SIDE NAVBAR */}
            {!isHideSteps.includes(step) && <SideNavbar
                template={template || ''}
                uri={state ? `/${ROOT_PATH}/state-specific/${templateType}/${state}/${template}` : `/${ROOT_PATH}/${templateType}/${template}`}
                stepName={stepName || ''}
                isHideSteps={isHideSteps}
            />}
            {/* MAIN FORM */}
            <MainForm
                nextBtn={nextBtnHandler}
                backBtn={backBtnHandler}
                stepDetails={stepDetails}
                isHideSteps={isHideSteps}
                isLoading={isLoading}
            />
            {/* FORM PREVIEW */}
            <FormPreview
                stepDetails={stepDetails}
                template={template || ''}
            />
            {/* Modal */}
            {/* TODO:CSS : Modal Width--Doing scrollable */}
            {showTemplateModal && <ModalComponent />}
        </div>
    )
}

export default SetTemplateDesign
