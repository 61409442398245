import axios, { AxiosRequestConfig } from 'axios'
import React, { useContext, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { templateActionEnum, DOCUMENT_PAPER_TYPE } from '../../Config/Constants'
import TemplateDataContext from '../../Contexts/TemplateData.context'
import { logger } from '../../Middleware/Logger'
import { getTemplateHtml } from '../../Services/TemplateServices'
import ErrorMessage from '../../Util/ErrorMessage'
import Template from '../Template/Template'
import LoaderComponent from './LoaderComponent'
import ModalTitle from './ModalTitle'
import { htmlToSvg } from '../../Services/HtmlToSvgService'

type Props = {}

const ModalComponent = (props: Props) => {
    const { state, dispatch } = useContext(TemplateDataContext)
    const { showTemplateModal, templates, templatePath, selectedState, isLoading } = state
    const { template } = useParams()
    const [title, setTitle] = useState<string>('')
    const [isModalClass, setIsModalClass] = useState<string>('letter_paper')
    const [htmlContent, setHtmlContent] = useState<string>('')
    const [svgData, setSvgData] = useState<string[]>([])

    const loadPaperTypeContent = async ({ html, paperType }: { html?: string, paperType: string }) => {
        dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: true })
        const paperSize = paperType
        const getHtmlData: any = document.getElementById('getHtml')

        try {
            const formData = new FormData()
            formData.append('html', getHtmlData?.innerHTML)
            formData.append('paperSize', paperSize)

            htmlToSvg(formData, setSvgData, dispatch)
        } catch (error) {
            if (!axios.isCancel(error)) {
                logger.error('🚀 ~ file: useEffect.tsx ~ line 47 ~ changeLayout ~ error', error)
                dispatch({ type: templateActionEnum.SET_ERROR, payload: { show: true, message: ErrorMessage.TEMPLATE_HTML_FAILED } })
            }
            dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: false })
        }
    }

    /* Fetch the template content and set title */
    const getTemplate = async (config: AxiosRequestConfig) => {
        if (template !== undefined &&
            templates[template] !== undefined &&
            templates[template].title !== undefined
        ) {
            setTitle(templates[template].title)
            return
        }
        const { data: html } = await getTemplateHtml({
            endPoint: templatePath,
            templateKey: template || '',
            config,
            state: selectedState
        })

        if (html) {
            await loadPaperTypeContent({ html, paperType: DOCUMENT_PAPER_TYPE[0] })
            setHtmlContent(html)
        }
        /* select the title from loaded templated */
        const matchingTitle = document.querySelector('.thumb-title')
        if (matchingTitle && matchingTitle.textContent) {
            setTitle(matchingTitle.textContent.trim())
        } else {
            /* if title is not empty then fetch from template */
            /* replace all the '\r\n'  */
            /* match the div with class 'thumb-title' and get the title  */
            const matching: string[] | null = html
                .replaceAll('\r\n', '')
                .match(/<div\sclass="[^"]*?thumb-title[^"]*?"[\r\n]?.*?>(.*?)<\/div>/)

            if (matching) {
                matching.forEach((element: string, index: number) => {
                    if (index === 1) setTitle(element)
                })
            }
        }
    }

    const changeLayout = async (e: any): Promise<void> => {
        dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: true })
        if (e.target.value === 'legal') {
            setIsModalClass('legal_paper')
        } else {
            setIsModalClass('letter_paper')
        }
        try {
            const paperType = e.target.value
            dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: true })
            await loadPaperTypeContent({ paperType })
        } catch (error) {
            if (!axios.isCancel(error)) {
                logger.error('🚀 ~ file: useEffect.tsx ~ line 47 ~ getTemplate ~ error', error)
                dispatch({ type: templateActionEnum.SET_ERROR, payload: { show: true, message: ErrorMessage.TEMPLATE_HTML_FAILED } })
            }
        } finally {
            dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: false })
        }
    }

    useEffect(() => {
        dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: true })
        const cancelToken = axios.CancelToken.source()
        const config = { cancelToken: cancelToken.token }
        try {
            getTemplate(config)
        } catch (error) {
            if (!axios.isCancel(error)) {
                logger.error('🚀 ~ file: useEffect.tsx ~ line 47 ~ getTemplate ~ error', error)
                dispatch({ type: templateActionEnum.SET_ERROR, payload: { show: true, message: ErrorMessage.TEMPLATE_HTML_FAILED } })
            }
        } finally {
            dispatch({ type: templateActionEnum.SET_IS_LOADING, payload: false })
        }
        return () => cancelToken.cancel()
    }, [template, selectedState])

    const handleClose = () => {
        dispatch({ type: templateActionEnum.SET_MODAL_STATE, payload: false })
    }

    return (
        <Modal className={(template && htmlContent) ? isModalClass : 'template-modal'} show={showTemplateModal} onHide={handleClose} backdrop="static" centered scrollable>
            <Modal.Header closeButton>
                <Modal.Title className='flex-grow-1'>
                    <ModalTitle title={title} changeLayout={changeLayout} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                {isLoading && <LoaderComponent />}
                {(template && htmlContent && !isLoading) && <Template svgData={svgData} templateKey={template} htmlContent={htmlContent} isInnerPage />}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal >
    )
}

export default ModalComponent
