import _ from 'lodash'
import { useContext, useEffect } from 'react'
import { jsonKeyMapping } from '../Config/Constants'
import { useNavigate, useParams } from 'react-router-dom'
import { TemplateObject } from '../Models/TemplateObject'
import TemplateDataContext from '../Contexts/TemplateData.context'

type NavigateRoutesType = {
    templates: TemplateObject,
    setStepDetails: Function,
    state?: string
}

export default async function useNavigateRoutes ({ templates, setStepDetails, state }: NavigateRoutesType): Promise<void> {
    const { templateType, template, stepName } = useParams()
    const { state: TemplateState } = useContext(TemplateDataContext)
    const { activeTemplateSteps } = TemplateState
    const navigate = useNavigate()
    // all the sub routes will change from this
    useEffect(() => {
        if (templates && template && stepName && activeTemplateSteps.length > 0) {
            const isExistingStep = _.find(activeTemplateSteps, { [jsonKeyMapping.SLUGIFY]: stepName })
            if (isExistingStep) {
                setStepDetails(isExistingStep)
            } else {
                setStepDetails(activeTemplateSteps[0])
            }
        } else if (template !== undefined && templates[template] && activeTemplateSteps.length > 0) {
            if (state) {
                navigate(`/state-specific/${templateType}/${state}/${template}/${activeTemplateSteps[0][jsonKeyMapping.SLUGIFY]}`)
                return
            }
            navigate(`/${templateType}/${template}/${activeTemplateSteps[0][jsonKeyMapping.SLUGIFY]}`)
        }
        return () => { }
    }, [templates, stepName, activeTemplateSteps, activeTemplateSteps.length])
}
