import React from 'react'
import moment from 'moment'
import { DEFAULT_RADIO_VALUE, DEFAULT_VALUE, formActionEnum } from '../Config/Constants'
import FormAction from '../Models/FormAction'
import { TemplateObject } from '../Models/TemplateObject'

type Parameters = {
    formData: TemplateObject,
    blankFormData: TemplateObject,
    dispatch: React.Dispatch<FormAction>
}

const renderFormData = ({ formData, blankFormData, dispatch }: Parameters) => {
    const tempFormData: TemplateObject = { ...formData }
    const tempBlankFormData: TemplateObject = { ...blankFormData }
    /* update the remove formData keys */
    for (const formKey in tempBlankFormData) {
        const getElements = Array.from(document.getElementsByClassName(formKey)) as HTMLElement[]
        getElements.map(element => {
            if (!formData[formKey]) {
                element.innerHTML = tempBlankFormData[formKey] || ''
                if (element.style && element.style.display) {
                    element.style.display = 'none'
                }
            }
            return element
        })
    }
    for (const formKey in tempFormData) {
        const getElements = Array.from(document.getElementsByClassName(formKey))
        getElements.map(element => {
            if (!blankFormData[formKey]) {
                const tempBlankData = {
                    [formKey]: element.innerHTML
                }
                dispatch({ type: formActionEnum.SET_BLANK_FORM_DATA, payload: tempBlankData })
            }
            const metaKeyName = `meta-data-${formKey}`
            /* conditional text show/hide */
            if (tempFormData[metaKeyName] !== undefined &&
                tempFormData[metaKeyName].selectedValues !== undefined &&
                tempFormData[metaKeyName].selectedValues.constructor.name === 'Array'
            ) {
                let isElementPresent = false
                tempFormData[metaKeyName].selectedValues.forEach((value: TemplateObject) => {
                    if (!value.id.match(/\s/)) {
                        // @ts-ignore: Object is possibly 'null'.
                        const elements: HTMLElement[] | null = document.querySelectorAll(`.${value.id}`)
                        if (elements) {
                            elements.forEach(element => {
                                isElementPresent = true
                                element.style.display = value.style
                            })
                        }
                    }
                })
                if (isElementPresent) { return element }
            }

            /* META DATA HANDLING */
            /* ADD MORE Input Text with separator */
            if (tempFormData[metaKeyName] !== undefined &&
                tempFormData[metaKeyName].addMoreElement === true &&
                tempFormData[metaKeyName].separator !== null
            ) {
                const id = tempFormData[metaKeyName].separatorId
                const values = []
                if (!tempFormData[metaKeyName].isGroupAdd) {
                    const inputFields = Object.keys(formData).filter(key => {
                        const re = new RegExp(`^${id}\\d$`, 'g')
                        return key.match(re)
                    })

                    inputFields.forEach(inputId => {
                        if (tempFormData[inputId]) {
                            values.push(tempFormData[inputId])
                        }
                    })
                }

                if (tempFormData[metaKeyName].isGroupAdd === true) {
                    const dynamicIndex = tempFormData[metaKeyName].dynamicIndex
                    const children = tempFormData[metaKeyName].children
                    const groupSeparator = tempFormData[metaKeyName].groupInputSeparator
                    if (dynamicIndex) {
                        dynamicIndex.forEach((inputIndex: number) => {
                            const valueMap: string[] = []
                            children.forEach((inputId: string) => {
                                if (tempFormData[`${inputId}-${inputIndex}`]) {
                                    valueMap.push(tempFormData[`${inputId}-${inputIndex}`])
                                }
                            })
                            if (valueMap.length > 0) {
                                values.push(valueMap.join(groupSeparator))
                            }
                        })
                    }
                }

                if (tempFormData[id]) {
                    values.unshift(tempFormData[id])
                }
                element.innerHTML = values.join(tempFormData[metaKeyName].separator) || tempBlankFormData[id] || ''
                return element
            }

            /* update the text if and only if the parent element is selected */
            if (tempFormData[metaKeyName] !== undefined &&
                tempFormData[tempFormData[metaKeyName].id] !== undefined
            ) {
                if (tempFormData[tempFormData[metaKeyName].id].constructor.name === 'String' &&
                    tempFormData[tempFormData[metaKeyName].id] !== tempFormData[metaKeyName].label) {
                    element.innerHTML = ''
                    return element
                }

                if (tempFormData[tempFormData[metaKeyName].id].constructor.name === 'Array' &&
                    !tempFormData[tempFormData[metaKeyName].id].includes(tempFormData[metaKeyName].label)) {
                    /* Checkbox : Add Your Own */
                    if (tempFormData[metaKeyName].checkboxAddYour) {
                        element.innerHTML = tempFormData[formKey] || tempBlankFormData[formKey] || ''
                        return element
                    }

                    element.innerHTML = ''
                    return element
                }
            }
            /* SHOW HIDE ELEMENT BASED UPON THE SELECTED VALUE */
            /* IGNORE : values with spaces  */
            if (tempFormData[metaKeyName] !== undefined &&
                tempFormData[tempFormData[metaKeyName].id] === tempFormData[metaKeyName].label &&
                tempFormData[metaKeyName].showElement &&
                tempFormData[metaKeyName].showElement.match(/\s/) === null) {
                // @ts-ignore: Object is possibly 'null'.
                const elements: HTMLElement[] | null = document.querySelectorAll(`.${tempFormData[metaKeyName].showElement}`)
                if (elements) {
                    elements.forEach((element: TemplateObject) => (element.style.display = 'inline-block'))
                }
            }

            if (tempFormData[metaKeyName] !== undefined &&
                tempFormData[metaKeyName].separator &&
                tempFormData[formKey].constructor.name === 'Array') {
                const separator = tempFormData[metaKeyName].separator
                element.innerHTML = tempFormData[formKey].join(separator)
                return element
            }

            if (tempFormData[metaKeyName] !== undefined &&
                tempFormData[metaKeyName].timeFormat) {
                const timeFormat = tempFormData[metaKeyName].timeFormat
                element.innerHTML = moment(tempFormData[formKey]).format(timeFormat).toString()
                return element
            }
            if ([DEFAULT_RADIO_VALUE, DEFAULT_VALUE].includes(tempFormData[formKey])) {
                element.innerHTML = ''
                return element
            }

            element.innerHTML = tempFormData[formKey] || tempBlankFormData[formKey] || ''

            return element
        })
    }
}

export default renderFormData
