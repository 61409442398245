import React from 'react'
import { ToastContainer, Toast } from 'react-bootstrap'
import { TOAST_AUTO_HIDE_AFTER } from '../../Config/Constants'

type Props = {
    message:string,
    show:boolean,
    closeHandler:(e?: React.MouseEvent | React.KeyboardEvent) => void
}

const ToastComponent = ({ message, show, closeHandler }:Props) => {
    return (<ToastContainer position="top-end" className="p-3">
        <Toast
            bg='danger'
            onClose={closeHandler}
            show={show}
            delay={TOAST_AUTO_HIDE_AFTER}
            autohide
        >
            <Toast.Header>
                <strong className="me-auto">Error !</strong>
            </Toast.Header>
            <Toast.Body className='Dark'>
                {message}
            </Toast.Body>
        </Toast>
    </ToastContainer>)
}

export default ToastComponent
