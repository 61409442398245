import React, { useState } from 'react'
import { BsLightbulb } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip'
import parser from 'html-react-parser'
import { jsonKeyMapping } from '../../Config/Constants'
import { TemplateObject } from '../../Models/TemplateObject'

type Props = {
    labelClass: string,
    tooltipClass: string,
    formElement: TemplateObject
}

const ToolTipWithLabelComponent = ({ labelClass, tooltipClass, formElement }: Props) => {
    const [show, setShow] = useState(true)
    return (
        <>
            <label htmlFor={formElement[jsonKeyMapping.FORM_ID]} className={`d-inline ${labelClass || ''}`}>
                {formElement[jsonKeyMapping.FORM_LABEL] || ''}
            </label>
            {/* issue fix : ReactTooltip.hide doesn't hide tooltip instantly  */}
            <span
                className='tooltip-qus'
                data-tip=''
                data-for={`button-tooltip-${formElement[jsonKeyMapping.FORM_ID]}`}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => {
                    setShow(false)
                    setTimeout(() => setShow(true), 50)
                }}
            >
                <BsLightbulb />
            </span>
            {
                show && <ReactTooltip
                    id={`button-tooltip-${formElement[jsonKeyMapping.FORM_ID]}`}
                    place="right"
                    effect="float"
                    multiline={true}
                    clickable={true}
                    offset={{ right: 5 }}
                    className='tooltip-max-width'
                >
                    {parser(formElement[jsonKeyMapping.FORM_SUB_TOOLTIP]) || ''}
                </ReactTooltip>
            }
        </>
    )
}

export default ToolTipWithLabelComponent
