import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import {
    jsonKeyMapping,
    NO_GROUP,
    templateActionEnum
} from '../../../Config/Constants'
import TemplateDataContext from '../../../Contexts/TemplateData.context'
import { TemplateObject } from '../../../Models/TemplateObject'

type Props = {
    step: TemplateObject;
    uri: string;
    isVisited: boolean;
    isActive: boolean;
};

const NavBarItem = ({ step, uri, isActive, isVisited }: Props) => {
    const { dispatch } = useContext(TemplateDataContext)

    /* side navbar handler */
    const handleClose = () => {
        dispatch({ type: templateActionEnum.SET_NAVBAR_STATE, payload: false })
        dispatch({ type: templateActionEnum.SET_ACTIVE_GROUP, payload: NO_GROUP })
    }

    return (
        <li
            className={`list-group-item body-font3 ${isActive && 'active fw-700'} ${isVisited && 'grey-color fw-700'
            } `}
            onClick={handleClose}
        >
            <Link to={`${uri}/${step[jsonKeyMapping.SLUGIFY]}`}>
                <svg width="15" height="15" viewBox="0 0 100 100" className="me-3 dote">
                    <circle cx="50" cy="50" r="40" stroke="#000" strokeWidth="0" />
                </svg>
                {step[jsonKeyMapping.NAV_TITLE] || step[jsonKeyMapping.SLUG]}
            </Link>
        </li>
    )
}

export default NavBarItem
