import React, { useContext, useEffect, useState } from 'react'
import { getTemplateHtml } from '../../Services/TemplateServices'
import parser from 'html-react-parser'
import FormDataContext from '../../Contexts/FormData.context'
import TemplateOverlay from './TemplateOverlay/TemplateOverlay'
import TemplateOverlayAction from './TemplateOverlay/TemplateOverlayAction'
import TemplateDataContext from '../../Contexts/TemplateData.context'
import { logger } from '../../Middleware/Logger'
import axios from 'axios'
import { ROOT_PATH, jsonKeyMapping } from '../../Config/Constants'
import { TemplateObject } from '../../Models/TemplateObject'
import renderFormData from '../../Util/RenderFormData'

type Props = {
    templateKey: string,
    isInnerPage: boolean,
    htmlContent?: string,
    index?: number,
    svgData?: string[],
}

const Template = ({ templateKey, isInnerPage, htmlContent, index, svgData }: Props) => {
    const { state: templateState } = useContext(TemplateDataContext)
    const { templatePath, selectedState, templates } = templateState
    const { state: formState, dispatch } = useContext(FormDataContext)
    const { formData, activeColor, blankFormData } = formState
    /* Set the initial route to first step of the template */
    let initialStep = null
    if (templates[templateKey] !== undefined && templates[templateKey].steps) {
        initialStep = templates[templateKey].steps[0][jsonKeyMapping.SLUGIFY]
    }
    const uri = `/${templatePath}/${selectedState && `${selectedState}/`}${templateKey}${initialStep && `/${initialStep}`}`
    const templateIndex = index && index + 1
    const path = templatePath.replace('state-specific/', '')
    const documentName = `${path}-${templateIndex === 0 ? 1 : templateIndex}`
    const folderPath = path + `${selectedState && `/${selectedState}`}`
    const [formHtml, setFormHtml] = useState<string>('')

    /* load the html template */
    useEffect(() => {
        if (htmlContent === undefined) {
            const cancelToken = axios.CancelToken.source()
            const config = { cancelToken: cancelToken.token }
            const getTemplate = async (templateKey: string) => {
                try {
                    const { data } = await getTemplateHtml({
                        endPoint: templatePath,
                        templateKey,
                        config,
                        state: selectedState
                    })
                    setFormHtml(data)
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        logger.log('🚀 ~ file: useEffect.tsx ~ line 74 ~ getTemplate ~ error', error)
                    }
                }
            }
            getTemplate(templateKey)
            return () => cancelToken.cancel()
        } else {
            setFormHtml(htmlContent)
        }
    }, [templateKey, templatePath, selectedState])

    /* Set the Background of Template */
    useEffect(() => {
        const themeBackgroundColors = document.getElementsByClassName('theme-color')
        const themeFontColors = document.getElementsByClassName('theme-color-font')
        if (themeBackgroundColors && themeBackgroundColors.length > 0) {
            Array.from(themeBackgroundColors).map((themeBackgroundColor: TemplateObject) => { themeBackgroundColor.style.background = `#${activeColor.colorHashValue}`; return themeBackgroundColor })
        }
        if (themeFontColors && themeFontColors.length > 0) {
            Array.from(themeFontColors).map((themeFontColor: TemplateObject) => { themeFontColor.style.color = `#${activeColor.colorHashValue}`; return themeFontColor })
        }

        return () => { }
    }, [htmlContent, formHtml, activeColor])

    /* Set the InputValues of Template */
    useEffect(() => {
        try {
            renderFormData({ formData, blankFormData, dispatch })
        } catch (error) {
            logger.error('🚀 ~ file: useEffect.tsx ~ line 100 ~ setInputValues ~ error', error)
        }
        return () => { }
    }, [formData, formHtml, Object.keys(formData).length, blankFormData, Object.keys(blankFormData).length])

    return (
        <>
            {
                svgData && svgData.length > 0
                    ? svgData.map((item, index) => {
                        return (
                            <img className='w-100' src={item} key={index} />
                        )
                    })
                    : <>
                        {!isInnerPage && formHtml
                            ? <TemplateOverlay
                                actionElement={<TemplateOverlayAction
                                    documentName={documentName}
                                    folderPath={folderPath}
                                    uri={`/${ROOT_PATH}${uri}`}
                                    actionName="Select Template"
                                />}
                            >
                                <div className='thumb-box-inner'>
                                    <div className="content-box">
                                        {parser(formHtml)}
                                    </div>
                                </div>
                            </TemplateOverlay>
                            : <span id='getHtml'>
                                {parser(formHtml)}
                            </span>}
                    </>
            }
        </>
    )
}

export default Template
