import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import ChooseTemplateCard from '../../Components/Template/ChooseTemplateCard'
import { TemplateType } from '../../Models/ChooseTemplate'
import { apiConf, templateActionEnum } from '../../Config/Constants'
import TemplateDataContext from '../../Contexts/TemplateData.context'

const Home = () => {
    const [chooseTemplateTypeList, setChooseTemplateTypeList] = useState({} as TemplateType)
    const { dispatch } = useContext(TemplateDataContext)

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(`${apiConf.developmentBaseUrlForObject}/HomePage.json`)
                if (response.data) {
                    setChooseTemplateTypeList(response.data)
                } else {
                    throw response
                }
            } catch (error) {
                console.error('🔥 ~ fetchHomeData ~ error', error)
            }
        })()
    }, [])

    useEffect(() => {
        dispatch({ type: templateActionEnum.LOAD_TEMPLATE, payload: [] })
    }, [])

    return (
        <section className="body-section">
            <div className="container">
                <div className="card-header headerheight">
                </div>
                <div className="title-wrap text-center">
                    <h1 className="page-title">Choose The Template Type</h1>
                </div>
                <div className="row mt-5 p-2">
                    {Object.keys(chooseTemplateTypeList).length
                        ? (<Tab.Container id="left-tabs-example" defaultActiveKey={Object.keys(chooseTemplateTypeList)[0]}>
                            <Row>
                                <Col sm={12}>
                                    <Nav variant="pills" className="d-flex justify-content-center">
                                        <>
                                            {Object.keys(chooseTemplateTypeList).map((templateKey, nvaLinkIndex) => (
                                                <Nav.Item className='ms-3' key={nvaLinkIndex}>
                                                    <Nav.Link eventKey={templateKey} className='tabs'>{templateKey}</Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </>
                                    </Nav>
                                </Col>
                                <Col sm={12} className='mt-5'>
                                    <Tab.Content>
                                        {Object.keys(chooseTemplateTypeList).map((templateKey, nvaLinkIndex) => (
                                            <Tab.Pane eventKey={templateKey} key={nvaLinkIndex}>
                                                <Row>
                                                    {chooseTemplateTypeList[templateKey].map((template, index) => (
                                                        <Col md={6} key={index}>
                                                            <ChooseTemplateCard {...template} />
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Tab.Pane>
                                        )
                                        )}

                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>)
                        : <></>}
                </div>
            </div>
        </section>
    )
}

export default Home
