import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

type Props = {}

const LoaderComponent = (props: Props) => {
    return (<div className='d-flex justify-content-center'>
        <Spinner animation="border" className='spinner' style={{ width: '7rem', height: '7rem', textAlign: 'center' }} />
    </div>)
}

export default LoaderComponent
