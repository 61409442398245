import TemplateAction from '../../Models/TemplateAction'
import { TemplateContext } from '../../Models/TemplateContext'
import produce from 'immer'
import Action from './Action'
import { templateActionEnum, sessionStorageKey, jsonKeyMapping } from '../../Config/Constants'
import defaultState from './defaultState'
import { TemplateObject } from '../../Models/TemplateObject'

const TemplateReducer = (state:TemplateContext, action:TemplateAction) => {
    const newState = produce(state, draft => {
        switch (action.type) {
        case templateActionEnum.SET_TEMPLATE_PATH:
            draft.templatePath = Action.setTemplatePath(action.payload)
            break
        case templateActionEnum.LOAD_TEMPLATE: {
            const { keys, payload } = Action.loadTemplate(action.payload)
            draft.templateKeys = keys
            draft.templates = payload
        }
            break
        case templateActionEnum.SET_TEMPLATE:
            draft.templates = Action.setTemplate(action.payload)
            break
        case templateActionEnum.UPDATE_TEMPLATE_STEP:{
            const { templateKey, steps } = action.payload
            draft.templates[templateKey][jsonKeyMapping.STEPS] = steps
        }
            break
        case templateActionEnum.SET_SELECTED_STATE:
            draft.selectedState = Action.setSelectedState(action.payload)
            break
        case templateActionEnum.SET_NO_TEMPLATE_FOUND:
            draft.noTemplateFound = action.payload
            break
        case templateActionEnum.SET_IS_LOADING:
            draft.isLoading = Action.setIsLoading(action.payload)
            break
        case templateActionEnum.SET_MODAL_STATE:
            draft.showTemplateModal = Action.setModalState(action.payload)
            break
        case templateActionEnum.SET_ERROR:
            draft.error = Action.setError(action.payload)
            break
        case templateActionEnum.SET_ACTIVE_TEMPLATE_HTML:
            draft.activeTemplateHtml = action.payload
            break
        case templateActionEnum.SET_ACTIVE_TEMPLATE_STEPS:
            draft.activeTemplateSteps = action.payload
            break
        case templateActionEnum.SET_ACTIVE_TEMPLATE_GROUP_BY:
            draft.activeTemplateGroupBy = action.payload
            break
        case templateActionEnum.UPDATE_ACTIVE_TEMPLATE_STEP:{
            draft.activeTemplateGroupBy = action.payload
            const flattenSteps:TemplateObject[] = []
            action.payload.forEach(step => {
                if (step.groupName !== undefined) {
                    flattenSteps.push(...step[step.groupName])
                    return
                }
                flattenSteps.push(step)
            })
            draft.activeTemplateSteps = flattenSteps
        }
            break
        case templateActionEnum.SET_CONDITIONAL_STEPS:
            draft.conditionalSteps = action.payload
            break
        case templateActionEnum.SET_NAVBAR_STATE:
            draft.sideNavbarState = action.payload
            break
        case templateActionEnum.SET_STEP_FLAG:
            draft.isStepsOverride = action.payload
            break
        case templateActionEnum.SET_ACTIVE_GROUP:
            draft.activeGroup = action.payload
            break
        case templateActionEnum.RESET:
            draft = defaultState
            break
        default:
            throw new Error('Action Type Not Define')
        }
    })
    /* SAVE IN SESSION STORAGE */
    sessionStorage.setItem(sessionStorageKey.TEMPLATE_STATE, JSON.stringify(newState))
    return newState
}

export default TemplateReducer
