import React from 'react'
import './App.css'
import AppRoutes from './AppRoutes'
import { ErrorBoundary } from 'react-error-boundary'
import InternalServer from './Pages/Others/InternalServer'
import FormDataContext, { FormDataContextProvider } from './Contexts/FormData.context'

function App () {
    return (
        <ErrorBoundary
            FallbackComponent={InternalServer}
            onError={(error, errorInfo) => ({ error, errorInfo })}
            onReset={() => {
                // reset the state of your app
            }}
        >
            <FormDataContextProvider>
                <FormDataContext.Consumer>
                    {(context) => (
                        <AppRoutes />
                    )}
                </FormDataContext.Consumer>
            </FormDataContextProvider>
        </ErrorBoundary>
    )
}

export default App
