import { AxiosRequestConfig } from 'axios'
import axios from '../Config/Axios'
import * as _ from 'lodash'
import { TemplateObject } from '../Models/TemplateObject'
import { readCookie } from '../Util/helper'

const fetchTemplates = async ({ endPoint, jsonFileName, config }: { endPoint: string, jsonFileName: string, config?: AxiosRequestConfig }) => {
    return await axios.get(`${endPoint}/assets/json/${jsonFileName}`, config)
}

const getTemplateHtml = async ({ endPoint, templateKey, state, config }: { endPoint: string, templateKey: string, state?: string, config?: AxiosRequestConfig }) => {
    if (state) {
        return await axios.get(`/${endPoint}/temp/${_.capitalize(state)}/${templateKey}.html`, config)
    }
    return await axios.get(`/${endPoint}/temp/${templateKey}.html`, config)
}

const getTemplatePaperType = async ({ html, paperSize, config }: { html: string, paperSize: string, config?: AxiosRequestConfig }) => {
    // return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/request-paper-size`, { html, paperSize }, config)
    return await axios.post('http://html2svg.mowebapp.com/Home/HtmlString2Svg', { html, paperSize }, config)
}

const processFormRequest = async ({ formData, config }: { formData: TemplateObject, config?: AxiosRequestConfig }) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/process-request`, { ...formData }, config)
}

const createDoc = async (data: any) => {
    const jwtToken = readCookie('jwtToken')
    const headers: object = { Authorization: 'Bearer' + ' ' + jwtToken }
    const createDocEndpoint = `${process.env.REACT_APP_DOCBUILDER_URL}/doc`
    return await axios.post(createDocEndpoint, data, headers)
}

const createParagraphService = async (docId: string, data: object) => {
    const jwtToken = readCookie('jwtToken')
    const headers: object = { Authorization: 'Bearer' + ' ' + jwtToken }
    const createParaEndPoint = `${process.env.REACT_APP_DOCBUILDER_URL}/doc/${docId}/paragraph`

    return await axios.post(createParaEndPoint, data, headers)
}

const updateParagraphService = async (docId: string, paraId: string, data: object) => {
    const jwtToken = readCookie('jwtToken')
    const headers: object = { Authorization: 'Bearer' + ' ' + jwtToken }
    const updateParagraphEndpoint = `${process.env.REACT_APP_DOCBUILDER_URL}/doc/${docId}/paragraph/${paraId}`
    return await axios.put(updateParagraphEndpoint, data, headers)
}

const updateDoc = async (docId: string, data: any) => {
    const updateDocName = `${process.env.REACT_APP_DOCBUILDER_URL}/doc/${docId}`
    return await axios.put(updateDocName, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const getPdfSimpliDoc = async (docId: string) => {
    const jwtToken = readCookie('jwtToken')
    const headers: object = { Authorization: 'Bearer' + ' ' + jwtToken }
    const getDocEndpoint = `${process.env.REACT_APP_DOCBUILDER_URL}/doc/${docId}`
    return await axios.get(getDocEndpoint, headers)
}

export { fetchTemplates, getTemplateHtml, getTemplatePaperType, processFormRequest, createDoc, createParagraphService, updateParagraphService, getPdfSimpliDoc, updateDoc }
