import React, { useContext } from 'react'
import { NavDropdown } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { jsonKeyMapping, templateActionEnum } from '../../../Config/Constants'
import TemplateDataContext from '../../../Contexts/TemplateData.context'
import { TemplateObject } from '../../../Models/TemplateObject'
type Props = {
    steps: TemplateObject[];
    uri: string;
};

const DropDownItems = ({ steps, uri }: Props) => {
    const { state, dispatch } = useContext(TemplateDataContext)
    const { activeTemplateSteps } = state
    const navigate = useNavigate()

    const { stepName } = useParams()
    let isVisited = false
    /* side navbar button handler */
    const handleClose = (step: TemplateObject) => {
        navigate(`${uri}/${step[jsonKeyMapping.SLUGIFY]}`)
        dispatch({ type: templateActionEnum.SET_NAVBAR_STATE, payload: false })
    }
    return (
        <>
            {steps.map((step: TemplateObject, index: number) => {
                const isActive = stepName === step[jsonKeyMapping.SLUGIFY]
                /* Find the current step index */
                const stepIndex = activeTemplateSteps.findIndex((step) => step[jsonKeyMapping.SLUGIFY] === stepName)
                /* compare the current step index with the steps   */
                for (let index = 0; index < activeTemplateSteps.length; index++) {
                    if (activeTemplateSteps[index][jsonKeyMapping.SLUGIFY] === step[jsonKeyMapping.SLUGIFY]) {
                        stepIndex > index ? (isVisited = true) : (isVisited = false)
                    }
                }
                return (
                    <NavDropdown.Item
                        className={`ms-2 p-2 ${isActive && 'active fw-700'} ${isVisited && 'grey-color fw-700'}`}
                        key={index}
                        onClick={() => handleClose(step)}
                    >
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 100 100"
                            className="me-3 dote"
                        >
                            <circle cx="50" cy="50" r="40" stroke="#000" strokeWidth="0" />
                        </svg>
                        {step[jsonKeyMapping.NAV_TITLE] || step[jsonKeyMapping.SLUG]}
                    </NavDropdown.Item>
                )
            })}
        </>
    )
}

export default DropDownItems
