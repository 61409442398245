import React, { ReactElement } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getPdfSimpliDoc, updateDoc } from '../../../Services/TemplateServices'
import { getDocIds } from '../../../Util/helper'

type TemplateOverlayProps = {
    uri: string
    actionName: string,
    documentName:any,
    folderPath:any
}

export default function TemplateOverlayAction ({ uri, actionName, documentName, folderPath } : TemplateOverlayProps) : ReactElement {
    const { templateType } = useParams()
    const templateID :string = templateType as string

    const onSelectTemplate = async () => {
        const getDocId = await getDocIds()
        if (getDocId) {
            let docId = ''
            for (const key in getDocId) {
                if (key.includes(templateID)) {
                    docId = getDocId[templateID]
                }
            }
            if (docId !== '') {
                const getDocuments = await getPdfSimpliDoc(docId)
                const data:any = getDocuments.data
                data.folderPath = folderPath
                data.name = documentName
                await updateDoc(docId, data)
            }
        }
    }

    return (
        <Link to={uri} >
            <button onClick={onSelectTemplate} className="btn primary-button primary-button-select-template">{actionName}</button>
        </Link>
    )
}
