import React from 'react'
import InputTextDynamicInput from './InputTextDynamicInput'
import { jsonKeyMapping } from '../../../Config/Constants'
import { TemplateObject } from '../../../Models/TemplateObject'

type Props = {
    parentClass: string,
    formElement: TemplateObject,
    handleDynamicInput: Function,
    inputValue: string,
    setInputValue: Function,
    textIsDisabled: any,
    setTextIsDisabled: Function,
    inputRef: any,
    setInputRef: any
}

const InputRadioDynamic = ({ parentClass, formElement, handleDynamicInput, inputValue, setInputValue, textIsDisabled, setTextIsDisabled, inputRef, setInputRef }: Props) => {
    const handleRadioChange = (e: any) => {
        if (e.target.checked) {
            handleDynamicInput(formElement[jsonKeyMapping.FORM_ID], inputValue || '', true)
            // setTextIsDisabled(false);
        } else {
            // setTextIsDisabled(true);
            setInputValue('')
        }
        return true
    }

    return (
        <div className="d-inline-block">
            <div className='d-flex align-items-center' >
                <input
                    className={'mr-1'}
                    type="radio"
                    name={formElement[jsonKeyMapping.FORM_ID]}
                    value={inputValue || ''}
                    onChange={handleRadioChange}
                ></input>
                <InputTextDynamicInput handleDynamicInput={handleDynamicInput} formElement={formElement} inputIsDisabled={textIsDisabled} setInputValue={setInputValue} value={inputValue} />
            </div>
        </div>
    )
}

export default InputRadioDynamic
