import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
type Props = {
    mainComponent: React.ReactNode
}

const AppLayout = ({ mainComponent }: Props) => {
    return (
        <>
            {/* Header */}
            <Header />

            {/* Main */}
            {mainComponent}

            {/* Footer */}
            <Footer />
        </>
    )
}

export default AppLayout
