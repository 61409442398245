import React, { useContext } from 'react'
import Select from 'react-select'
import FormDataContext from '../../../Contexts/FormData.context'
import { jsonKeyMapping } from '../../../Config/Constants'
import produce from 'immer'
import { TemplateObject } from '../../../Models/TemplateObject'
import InputWrapper from './InputWrapper'
import InputContext from '../../../Contexts/Input.Context'

type Props = {
    formElement: TemplateObject,
    handleSelectInput: Function,
    showLabel?: boolean
}

const InputSelect = ({ formElement, handleSelectInput, showLabel = true }: Props) => {
    /* Error : Uncaught TypeError: Cannot assign to read only property '' of object '#<Object>' Issue Fix */
    formElement = produce(formElement, (draft: { options: TemplateObject[] }) => {
        draft.options = formElement.options.map((formOption: TemplateObject) => ({ ...formOption, label: formOption.text }))
    })
    const { state } = useContext(FormDataContext)
    const { setConditionalInput } = useContext(InputContext)
    const { formData } = state
    const selectedIndex = formElement.options.find((option: TemplateObject) => (option[jsonKeyMapping.FORM_TEXT] === formData[formElement[jsonKeyMapping.FORM_ID]])) || null

    /* add more fields */
    const loadFields = (e: TemplateObject) => {
        let conditionalInput: null | TemplateObject = null
        /* Add more Fields based upon any selected value */
        if (e.text !== '' && formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] !== undefined) {
            conditionalInput = {
                formElement: formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS],
                parentLabel: formElement[jsonKeyMapping.FORM_LABEL],
                value: formElement[jsonKeyMapping.FORM_VALUE]
            }
        }

        const hasMoreFields = formElement.options.find((option: TemplateObject) => (e.text === option[jsonKeyMapping.FORM_TEXT] && option[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] !== undefined))
        /* Add more Fields based upon selected value */
        if (e.text !== '' && hasMoreFields) {
            conditionalInput = {
                formElement: hasMoreFields[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS],
                parentLabel: formElement[jsonKeyMapping.FORM_ID],
                value: hasMoreFields[jsonKeyMapping.FORM_LABEL]
            }
        }

        setConditionalInput(conditionalInput)
        handleSelectInput(e, formElement[jsonKeyMapping.FORM_ID])
    }

    return (
        <InputWrapper
            formElement={formElement}
            label={formElement[jsonKeyMapping.FORM_LABEL] || ''}
        >
            <Select
                id={formElement[jsonKeyMapping.FORM_ID]}
                value={selectedIndex}
                isMulti={formElement.multiple}
                options={formElement.options}
                name={formElement[jsonKeyMapping.FORM_ID]}
                className={`basic-multi-select ${formElement.inputClass || ''}`}
                classNamePrefix="select"
                onChange={(e) => loadFields(e)}
            />
        </InputWrapper>
    )
}

export default InputSelect
