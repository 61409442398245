import React, { useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import AppLayout from './Components/AppLayout/AppLayout'
import About from './Pages/About/About'
import ContactUs from './Pages/ContactUs/ContactUs'
import InternalServer from './Pages/Others/InternalServer'
import NotFound from './Pages/Others/NotFound'
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy'
import TermsConditions from './Pages/TermsConditions/TermsConditions'
import Home from './Pages/Home/Home'
import StateSpecific from './Pages/StateSpecific/StateSpecific'
import LoadTemplate from './Pages/LoadTemplate/LoadTemplate'
import SkipSelectState from './Pages/SelectStateSpecificTemplate/SkipSelectState'
import TemplateDataContext from './Contexts/TemplateData.context'
import { ROOT_PATH, templateActionEnum } from './Config/Constants'
import TemplateLayout from './Components/AppLayout/TemplateLayout'
import SetTemplateDesign from './Components/SetTemplate/SetTemplateDesign'

const AppRoutes = () => {
    /* Reset THE Toast Modal on page refresh and reload */
    const location = useLocation()
    const { dispatch } = useContext(TemplateDataContext)
    useEffect(() => {
        dispatch({ type: templateActionEnum.SET_ERROR, payload: { show: false } })
        return () => { }
    }, [dispatch, location])

    return (
        <Routes>
            <Route path={`/${ROOT_PATH}`} element={<AppLayout mainComponent={<Home />} />} />
            <Route path={`/${ROOT_PATH}/:templateType`} element={<AppLayout mainComponent={<LoadTemplate />} />} />
            <Route path={`/${ROOT_PATH}/:templateType/:template`} element={<TemplateLayout mainComponent={<SetTemplateDesign />} />} />
            <Route path={`/${ROOT_PATH}/:templateType/:template/:stepName`} element={<TemplateLayout mainComponent={<SetTemplateDesign />} />} />
            <Route path={`/${ROOT_PATH}/state-specific/:templateType`} element={<AppLayout mainComponent={<SkipSelectState><StateSpecific /></SkipSelectState>} />} />
            <Route path={`/${ROOT_PATH}/state-specific/:templateType/:state`} element={<AppLayout mainComponent={<LoadTemplate />} />} />
            <Route path={`/${ROOT_PATH}/state-specific/:templateType/:state/:template`} element={<TemplateLayout mainComponent={<SetTemplateDesign />} />} />
            <Route path={`/${ROOT_PATH}/state-specific/:templateType/:state/:template/:stepName`} element={<TemplateLayout mainComponent={<SetTemplateDesign />} />} />
            <Route path={`/${ROOT_PATH}/about`} element={<AppLayout mainComponent={<About />} />} />
            <Route path={`/${ROOT_PATH}/privacy-policy`} element={<AppLayout mainComponent={<PrivacyPolicy />} />} />
            <Route path={`/${ROOT_PATH}/terms-conditions`} element={<AppLayout mainComponent={<TermsConditions />} />} />
            <Route path={`/${ROOT_PATH}/contactUs`} element={<AppLayout mainComponent={<ContactUs />} />} />
            {/* Other Routes Please Do Not Create Any Routes After This Line */}
            <Route path="/500" element={<InternalServer />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Navigate to={`/${ROOT_PATH}`} />} />
        </Routes>
    )
}

export default AppRoutes
