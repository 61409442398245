import React, { ReactElement, useContext } from 'react'
import slugify from 'slugify'
import { formActionEnum } from '../../../Config/Constants'
import FormDataContext from '../../../Contexts/FormData.context'
import { TemplateObject } from '../../../Models/TemplateObject'
import InputSelect from './InputSelect'

type Props = {
    id:string
    options: TemplateObject,
    setOption:Function
}

export default function StateSpecificDropDown ({ id, options, setOption }:Props): ReactElement {
    const { dispatch } = useContext(FormDataContext)
    const setFormContextData = (id: string, value: string) => {
        // Save elementId and Value in FormData Context
        const formElementData = {
            [id]: value
        }
        dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })
    }

    const loadSelectedOptions = async (event: TemplateObject, inputId: string) => {
        setOption(slugify(event.text.toLowerCase()))
        setFormContextData(id, event.text)
    }

    return (<InputSelect showLabel={false} formElement={options} handleSelectInput={loadSelectedOptions} />)
}
