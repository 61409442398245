import produce from 'immer'
import Action from './Action'
import { FormContext } from '../../Models/FormContext'
import FormAction from '../../Models/FormAction'
import { formActionEnum, sessionStorageKey } from '../../Config/Constants'
import defaultState from './defaultState'

const FormReducer = (state:FormContext, action:FormAction) => {
    const newState = produce(state, draft => {
        switch (action.type) {
        case formActionEnum.SET_FORM_DATA:
            draft.formData = { ...state.formData, ...action.payload }
            break
        case formActionEnum.SET_DOC_ID:
            draft.docId = Action.setDocId(action.payload)
            break
        case formActionEnum.SET_PARAGRAPH:
            draft.paragraph = { ...state.paragraph, ...action.payload }
            break
        case formActionEnum.REMOVE_FORM_DATA:
            action.payload.forEach(key => delete draft.formData[key])
            break
        case formActionEnum.RESET_FORM_DATA:
            draft.formData = {}
            break
        case formActionEnum.SET_STEP_DATA:
            draft.stepData = { ...state.stepData, ...action.payload }
            break
        case formActionEnum.REMOVE_STEP_DATA:
            action.payload.forEach(key => delete draft.stepData[key])
            break
        case formActionEnum.RESET_STEP_DATA:
            draft.formData = {}
            break
        case formActionEnum.SET_BLANK_FORM_DATA:
            draft.blankFormData = { ...state.blankFormData, ...action.payload }
            break
        case formActionEnum.RESET_BLANK_FORM_DATA:
            draft.blankFormData = {}
            break
        case formActionEnum.SET_COLOR:
            draft.activeColor = Action.setActiveColor(action.payload)
            break
        case formActionEnum.RESET:
            draft = defaultState
            break
        default:
            throw new Error('Action Type Not Define')
        }
    })

    /* SAVE IN SESSION STORAGE */
    sessionStorage.setItem(sessionStorageKey.FORM_STATE, JSON.stringify(newState))
    return newState
}

export default FormReducer
