import React, { ReactElement, useContext, useEffect, useState } from 'react'
import TemplateHeader from '../../Header/TemplateHeader'
import { jsonKeyMapping, templateActionEnum } from '../../../Config/Constants'
import TemplateDataContext from '../../../Contexts/TemplateData.context'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Nav from '../TemplateStep/Nav'
import useLoadSteps from '../../../Hooks/LoadSteps'

type Props = {
    template: string,
    uri: string,
    stepName: string,
    isHideSteps: string[]
}

const SideNavbar = ({ template, uri, stepName, isHideSteps }:Props) : ReactElement => {
    const { state, dispatch } = useContext(TemplateDataContext)
    const { templates, stepNumber, totalSteps, activeTemplateSteps, sideNavbarState } = state

    /* side navbar handler */
    const handleClose = () => dispatch({ type: templateActionEnum.SET_NAVBAR_STATE, payload: false })
    const handleShow = () => dispatch({ type: templateActionEnum.SET_NAVBAR_STATE, payload: true })

    const [progressIndicator, setProgressIndicator] = useState<number>(0)

    useLoadSteps({
        templates
    })

    /* Calculate the progress indicator */
    useEffect(() => {
        /* calculate the current step */
        if (Object.keys(templates).length > 0 && activeTemplateSteps.length > 0) {
            const currentStepNumber = activeTemplateSteps.findIndex((x) => x[jsonKeyMapping.SLUGIFY] === stepName)
            const totalStepsCount = totalSteps || activeTemplateSteps.length
            setProgressIndicator((currentStepNumber + 1) / totalStepsCount * 100)
        }
        return () => {}
    }, [stepName, totalSteps, activeTemplateSteps.length, templates, stepNumber])

    return (
        <>
            <div className="col-lg-2 leftmenu">
                <TemplateHeader handleShow={handleShow} handleClose={handleClose} isOpen={sideNavbarState} />
                <Nav isHideSteps={isHideSteps} uri={uri} show={sideNavbarState} progressIndicator={progressIndicator} />
            </div>
            {/* MOBILE NAVBAR */}
            <Offcanvas show={sideNavbarState} onHide={handleClose} backdrop={false}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav isHideSteps={isHideSteps} show={sideNavbarState} uri={uri} progressIndicator={progressIndicator} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default SideNavbar
