import React, { useContext, useEffect, useState } from 'react'
import FormDataContext from '../../../Contexts/FormData.context'
import { DYNAMIC_INSERT_FLAG } from '../../../environment'
import FormBuilder from '../../FormBuilder/FormBuilder'
import InputCheckboxDynamic from './InputCheckboxDynamic'
import { formActionEnum, jsonKeyMapping } from '../../../Config/Constants'
import * as _ from 'lodash'
import { TemplateObject } from '../../../Models/TemplateObject'
import { InputGroup } from 'react-bootstrap'
import InputWrapper from './InputWrapper'

type Props = {
    formElement: TemplateObject;
    handleCheckBoxSelect: Function;
};

const InputCheckbox = ({ formElement, handleCheckBoxSelect }: Props) => {
    const { state, dispatch } = useContext(FormDataContext)
    const { formData } = state
    const [isMaxLimitReached, setMaxLimitReached] = useState<Boolean>(false)

    useEffect(() => {
        if (formElement[jsonKeyMapping.FORM_ID]) {
            const previousData = formData[`meta-data-${formElement[jsonKeyMapping.FORM_ID]}`] || {}
            const formElementData = {
                [`meta-data-${formElement[jsonKeyMapping.FORM_ID]}`]: {
                    ...previousData,
                    separator: formElement[jsonKeyMapping.FORM_INPUT_SEPARATOR] || ','
                }
            }
            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })
        }

        return () => { }
    }, [])

    /* Dynamic Checkbox */
    const multipleSetYourOwnComponent = {
        isChecked: false,
        text: '',
        isSubmit: true,
        isFocusEnabled: false
    }
    const [multipleSetYourOwn, setMultipleSetYourOwn] = useState([multipleSetYourOwnComponent])

    useEffect(() => {
        // Set Default values for dynamic values
        if (formData[formElement[jsonKeyMapping.FORM_ID]] && formData[formElement[jsonKeyMapping.FORM_ID]].length) {
            let multipleSetYourOwnTempArray = []
            // Get all values from arrays
            const defaultValues = _.map(formElement.inputCheckbox, 'value')

            // Get differences from selected values to default values
            const differences = _.difference(
                formData[formElement[jsonKeyMapping.FORM_ID]],
                defaultValues
            )

            // Set in dynamic if we find any differences between the two arrays
            if (differences && differences.length > 0) {
                multipleSetYourOwnTempArray = differences.map((difference) => {
                    return {
                        ...multipleSetYourOwnComponent,
                        text: difference,
                        isChecked: true,
                        isSubmit: false
                    }
                })

                const multipleSetYourOwn = [
                    ...multipleSetYourOwnTempArray,
                    multipleSetYourOwnComponent
                ]

                setMultipleSetYourOwn(multipleSetYourOwn)

                /* max limit check */
                if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
                    const dynamicCheckboxLength = multipleSetYourOwn.filter(option => option.text !== '').length
                    if (dynamicCheckboxLength >= formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
                        setMaxLimitReached(true)
                    }
                }
            }
        }

        return () => { }
    }, [])

    const onSubmitCheckboxDynamic = (value: string, index: number) => {
        /* max limit check */
        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            const dynamicCheckBoxListLength = multipleSetYourOwn.filter(option => option.text !== '').length
            if (dynamicCheckBoxListLength >= formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
                return false
            }
        }

        const getCurrentIndexData = JSON.parse(JSON.stringify(multipleSetYourOwn))
        getCurrentIndexData[index].text = value
        if (getCurrentIndexData[index].text === '') {
            return false
        }
        getCurrentIndexData[index].isChecked =
            getCurrentIndexData[index].text !== ''
        getCurrentIndexData[index].isSubmit = false

        setMultipleSetYourOwn([
            ...getCurrentIndexData,
            { ...multipleSetYourOwnComponent, isFocusEnabled: true }
        ])
        handleCheckBoxSelect(
            formElement[jsonKeyMapping.FORM_ID],
            value,
            null,
            DYNAMIC_INSERT_FLAG.DYNAMIC_INSERT
        )

        /* max limit check */
        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            const dynamicCheckBoxListLength = multipleSetYourOwn.filter(option => option.text !== '').length + 1
            if (dynamicCheckBoxListLength >= formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
                setMaxLimitReached(true)
            }
        }
    }

    const removeCheckboxDynamic = (index: number) => {
        let getCurrentIndexData = JSON.parse(JSON.stringify(multipleSetYourOwn))
        const removeCheckboxText = getCurrentIndexData[index].text
        getCurrentIndexData.splice(index, 1)
        const checkIfAnyNonSubmittedLeft = getCurrentIndexData.some(
            (curData: TemplateObject) => curData.isSubmit === true
        )
        if (!getCurrentIndexData.length || !checkIfAnyNonSubmittedLeft) {
            getCurrentIndexData = [multipleSetYourOwnComponent]
        }
        setMultipleSetYourOwn(getCurrentIndexData)
        handleCheckBoxSelect(
            formElement[jsonKeyMapping.FORM_ID],
            removeCheckboxText,
            null,
            DYNAMIC_INSERT_FLAG.DYNAMIC_REMOVE
        )

        if (formElement[jsonKeyMapping.FORM_MAX_LIMIT]) {
            setMaxLimitReached(false)
        }
    }
    /* End Dynamic Checkbox */

    /* More Fields */

    const loadFields = ({ inputCheckbox, event }: { inputCheckbox: TemplateObject, event: React.ChangeEvent<HTMLInputElement> }) => {
        /* Checkbox with blank value and add your own case handled */
        if (
            inputCheckbox[jsonKeyMapping.FORM_VALUE] === '' &&
            inputCheckbox[jsonKeyMapping.FORM_IS_MULTIPLE_ADD_YOUR_OWN] === undefined &&
            inputCheckbox[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS]
        ) {
            const previousData = formData[`meta-data-${formElement[jsonKeyMapping.FORM_ID]}`] || {}
            const formElementData = {
                [`meta-data-${formElement[jsonKeyMapping.FORM_ID]}`]: {
                    ...previousData,
                    checkboxAddYour: event.target.checked
                }
            }
            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })
            return
        }
        handleCheckBoxSelect(formElement[jsonKeyMapping.FORM_ID], inputCheckbox.value, event, DYNAMIC_INSERT_FLAG.STATIC)
    }

    return (
        <InputWrapper
            formElement={formElement}
            label={formElement[jsonKeyMapping.FORM_LABEL] || ''}
        >
            {
                formElement.inputCheckbox.map((inputCheckbox: TemplateObject, indexInputCheckbox: number) => {
                    const isAddOwn = inputCheckbox[jsonKeyMapping.FORM_IS_MULTIPLE_ADD_YOUR_OWN] === 'true' && inputCheckbox[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] !== undefined
                    const previousData = formData[`meta-data-${formElement[jsonKeyMapping.FORM_ID]}`] || {}
                    const addMoreFields = (previousData.checkboxAddYour === true && inputCheckbox[jsonKeyMapping.FORM_VALUE] === '')
                    const id = indexInputCheckbox > 0 ? `${inputCheckbox[jsonKeyMapping.FORM_NAME]}_${indexInputCheckbox}` : formElement[jsonKeyMapping.FORM_ID]
                    return (
                        <div key={`${formElement[jsonKeyMapping.FORM_ID]}_${indexInputCheckbox}`}>
                            {/* load the checkbox */}
                            <InputGroup className={formElement.inputCheckbox.length < 3 ? 'd-inline' : ''}>
                                {/* load the Add your own */}
                                {isAddOwn
                                    ? (multipleSetYourOwn.map((syo, index: number) => {
                                        if (isMaxLimitReached && syo.text === '') return <></>
                                        return (<InputCheckboxDynamic
                                            checkboxData={syo}
                                            key={'index' + index}
                                            index={index}
                                            onSubmitCheckboxDynamic={onSubmitCheckboxDynamic}
                                            removeCheckboxDynamic={removeCheckboxDynamic}
                                        />)
                                    }))
                                    : (<>
                                        <input
                                            id={id}
                                            name={inputCheckbox.name}
                                            className={`mr-half form-check-input ${formElement.inputClass ? formElement.inputClass : ''}`}
                                            type="checkbox"
                                            key={'indexCheckbox' + indexInputCheckbox}
                                            value={inputCheckbox.value}
                                            checked={((formData[formElement[jsonKeyMapping.FORM_ID]] && formData[formElement[jsonKeyMapping.FORM_ID]].includes(inputCheckbox.value)) || addMoreFields)}
                                            onChange={(event) => loadFields({ event, inputCheckbox })}
                                        />
                                        <label className='mr-half input-checkbox-label form-radio-input' htmlFor={id}>{inputCheckbox[jsonKeyMapping.FORM_LABEL]}</label>
                                    </>)
                                }
                            </InputGroup>
                            {/* load add more fields */}
                            {
                                addMoreFields &&
                                <FormBuilder
                                    formGroup={inputCheckbox[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS]}
                                    hasParent={{
                                        [jsonKeyMapping.FORM_ID]: formElement[jsonKeyMapping.FORM_ID],
                                        [jsonKeyMapping.FORM_VALUE]: inputCheckbox[jsonKeyMapping.FORM_VALUE],
                                        showElement: inputCheckbox.value,
                                        checkboxAddYour: true
                                    }}
                                />
                            }
                        </div>
                    )
                })
            }
        </InputWrapper>
    )
}

export default InputCheckbox
