import moment from 'moment'
import { DOC_STAGE, EMPTY_GUID, ORDER, SECTION_CD, TYPE_OF_DOC } from '../Config/Constants'

export const readCookie = (name:string) => {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

export const createCookie = (name:string, value:string, days?:number, domain?:string) => {
    let expires = ''
    let _domain = ''
    if (days) {
        const date:any = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toGMTString()
    } else expires = ''

    if (domain) {
        _domain = '; domain=' + domain
    }
    document.cookie = name + '=' + value + expires + _domain + '; path=/;'
}

export const emptyDocument = (visitorGuid :string, templateType:string, templateNumber:string, state:any) => {
    let path = templateType
    if (state) {
        path = templateType + `/${state}`
    }

    const doc = {
        id: EMPTY_GUID,
        name: templateType + '-' + templateNumber,
        docStage: DOC_STAGE,
        createdOn: moment(new Date()),
        modifiedOn: moment(new Date()),
        tenantId: EMPTY_GUID,
        userId: EMPTY_GUID,
        visitorId: visitorGuid,
        typeOfDoc: TYPE_OF_DOC,
        paragraphs: [
            {
                id: EMPTY_GUID,
                docId: EMPTY_GUID,
                paraContents: [
                    {
                        id: EMPTY_GUID,
                        paraId: EMPTY_GUID,
                        content: JSON.stringify({}),
                        order: ORDER,
                        createdOn: moment(new Date()),
                        modifiedOn: moment(new Date())
                    }
                ],
                order: ORDER,
                paraCD: templateType,
                sectionCD: SECTION_CD,
                paraName: templateType
            }
        ],
        docStyle: {},
        originalDocId: EMPTY_GUID,
        templateCD: templateType,
        isDeleted: false,
        comment: null,
        isStarred: false,
        tags: null,
        shared: false,
        sharedEmail: [],
        renderPdfFileUrl: '',
        folderPath: path,
        locale: null,
        isParsedDoc: false,
        originalURL: null
    }

    return doc
}

export const createParagraph = (
    paragraphId:string, docGuid:string, paraContent:string, orderNo:number, templateType:string, contentId:string
) => {
    const para = {
        id: paragraphId,
        docId: docGuid,
        paraContents: [
            {
                id: contentId,
                paraId: paragraphId,
                content: paraContent,
                order: orderNo,
                createdOn: moment(new Date()),
                modifiedOn: moment(new Date())
            }
        ],
        order: orderNo,
        paraCD: templateType,
        sectionCD: SECTION_CD,
        paraName: templateType
    }
    return para
}

export const getDocIds = async () => {
    const getDocIds = localStorage.getItem('saveDocIds')
    let toParse = null
    if (getDocIds) {
        toParse = JSON.parse(getDocIds)
    }
    const storeValue = toParse
    return storeValue
}

export const saveDocIds = async (templateType:string, docId:string) => {
    const getDocId = await getDocIds()
    let storeValue:any = {}
    if (getDocId) {
        storeValue = getDocId
    }
    storeValue[templateType] = docId
    localStorage.setItem('saveDocIds', JSON.stringify(storeValue))
}

export const filterParagraph = (para:any) => {
    let paraId = ''
    let content = ''
    para.forEach((element:any) => {
        if (element.id !== EMPTY_GUID) {
            paraId = element.id
        }

        if (element.paraContents[0].id !== EMPTY_GUID) {
            content = JSON.parse(element.paraContents[0].content)
        }
    })

    return { paraId, content }
}
