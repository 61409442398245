import React, { ReactElement } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import * as _ from 'lodash'
import { DOCUMENT_PAPER_TYPE } from '../../Config/Constants'

type Props = {
    title:String,
    changeLayout:React.ChangeEventHandler<HTMLSelectElement>
}

const ModalTitle = ({ title, changeLayout }:Props) :ReactElement => {
    return (
        <div className='px-3'>
            <Row className='align-items-center'>
                <Col sm={8}>
                    {title}
                </Col>
                <Col sm={'auto'} className='ms-auto'>
                    <Form.Select
                        name="paperType"
                        id="paperType"
                        onChange={changeLayout}
                        aria-label="paper type"
                        size="sm"
                        className="change-width"
                    >
                        { DOCUMENT_PAPER_TYPE.map((option:string, optionIndex:number) => {
                            return (
                                <option key={optionIndex} value={option}>{_.capitalize(option)}</option>
                            )
                        })}
                    </Form.Select>
                </Col>
            </Row>
        </div>
    )
}

export default ModalTitle
