import React from 'react'
import { jsonKeyMapping } from '../../../Config/Constants'
import { TemplateObject } from '../../../Models/TemplateObject'

type Props = {
    value: string,
    handleDynamicInput: Function,
    inputIsDisabled: boolean,
    formElement : TemplateObject,
    setInputValue : Function
}

const InputTextDynamicInput = ({ value, setInputValue, handleDynamicInput, inputIsDisabled, formElement }: Props) => {
    return (
        <input
            type="text"
            className={'form-control'}
            placeholder={''}
            onChange={(e) => {
                handleDynamicInput(formElement[jsonKeyMapping.FORM_ID], e.target.value, true)
                setInputValue(e.target.value)
            }}
            value={value}
            disabled={inputIsDisabled}
        />
    )
}

export default InputTextDynamicInput
