import { Color } from '../../Models/Color'
import { TemplateObject } from '../../Models/TemplateObject'

const setFormData = (formData:TemplateObject) => formData

const setBlankFormData = (formData:TemplateObject) => formData

const setActiveColor = (colorObject:Color) => colorObject

const setDocId = (docId:string) => docId

export default {
    setFormData,
    setBlankFormData,
    setActiveColor,
    setDocId
}
