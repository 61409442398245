import { useContext, useEffect } from 'react'
import { DEFAULT_VALUE, jsonKeyMapping } from '../Config/Constants'
import FormDataContext from '../Contexts/FormData.context'
import InputContext from '../Contexts/Input.Context'
import { TemplateObject } from '../Models/TemplateObject'

type useLoadMoreFieldsType = {
    formElement: TemplateObject
}

const useLoadMoreFields = ({ formElement }: useLoadMoreFieldsType) => {
    const { setConditionalInput } = useContext(InputContext)
    const { state } = useContext(FormDataContext)
    const { formData } = state

    /* Load More Fields Based Upon Previous Selected Values */
    useEffect(() => {
        let conditionalInput: TemplateObject | null = null

        switch (formElement[jsonKeyMapping.FORM_TYPE]) {
        case 'radio': {
            if (formData[formElement[jsonKeyMapping.FORM_ID]] !== undefined) {
                const selectedValue = formData[formElement[jsonKeyMapping.FORM_ID]] || DEFAULT_VALUE
                formElement.inputRadio.forEach((input: TemplateObject) => {
                    const value = input.value || DEFAULT_VALUE
                    if (value === selectedValue && input[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] !== undefined) {
                        conditionalInput = {
                            formElement: input[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS],
                            parentLabel: input[jsonKeyMapping.FORM_LABEL],
                            value: input[jsonKeyMapping.FORM_VALUE]
                        }
                    }
                })
            }
        }
            break
        case 'text':
        case 'textarea':
        case 'date':
        case 'datetime': {
            if (
                formData[formElement[jsonKeyMapping.FORM_ID]] !== undefined &&
                formData[formElement[jsonKeyMapping.FORM_ID]] !== ''
            ) {
                if (formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] !== undefined) {
                    conditionalInput = {
                        formElement: formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS],
                        parentLabel: formElement[jsonKeyMapping.FORM_LABEL],
                        value: formElement[jsonKeyMapping.FORM_VALUE]
                    }
                }
            }
        }
            break
        case 'select': {
            if (
                formData[formElement[jsonKeyMapping.FORM_ID]] !== undefined &&
                    formData[formElement[jsonKeyMapping.FORM_ID]] !== ''
            ) {
                if (formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] !== undefined) {
                    conditionalInput = {
                        formElement: formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS],
                        parentLabel: formElement[jsonKeyMapping.FORM_LABEL],
                        value: formElement[jsonKeyMapping.FORM_VALUE]
                    }
                }
                const hasMoreFields = formElement.options.find((option:TemplateObject) => (formData[formElement[jsonKeyMapping.FORM_ID]] === option[jsonKeyMapping.FORM_TEXT] && option[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] !== undefined))

                if (hasMoreFields) {
                    conditionalInput = {
                        formElement: hasMoreFields[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS],
                        parentLabel: formElement[jsonKeyMapping.FORM_ID],
                        value: hasMoreFields[jsonKeyMapping.FORM_LABEL]
                    }
                }
            }
        }
            break

        default:
            break
        }
        setConditionalInput(conditionalInput)
        return () => {}
    }, [formElement])
}

export default useLoadMoreFields
