import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import { jsonKeyMapping } from '../../Config/Constants'
import { FAQ } from '../../Models/Faq'

type Props = {
    faqContentList: FAQ[];
};

export default function FaqComponent ({ faqContentList }: Props) {
    return (
        <OverlayTrigger
            trigger="click"
            placement='auto-start'
            rootClose
            overlay={
                <Popover>
                    {faqContentList.map((faq, index) => {
                        return (
                            <div key={index}>
                                <Popover.Header as="h4" className="faq-header">
                                    {faq[jsonKeyMapping.FAQ_HEADER]}
                                </Popover.Header>
                                <Popover.Body
                                    dangerouslySetInnerHTML={{
                                        __html: faq[jsonKeyMapping.FAQ_TEXT]
                                    }}
                                ></Popover.Body>
                            </div>
                        )
                    })}
                </Popover>
            }
        >
            <div className="d-flex align-items-center border-start ps-3 pointer">
                <BsFillQuestionCircleFill className="icons me-2 blue-color" size={24} />
                <p className="blue-color text-decoration-underline sm-font fw-600">
                    FAQ
                </p>
            </div>
        </OverlayTrigger>
    )
}
