import { TemplateContext } from '../../Models/TemplateContext'

const defaultState:TemplateContext = {
    templates: {},
    templatePath: '',
    stepNumber: 0,
    totalSteps: 0,
    stepsCompleted: [],
    showTemplateModal: false,
    activeTemplate: '',
    error: { show: false, message: '' },
    activeState: '',
    isLoading: false,
    templateKeys: [],
    selectedState: '',
    baseUri: '',
    activeTemplateHtml: '',
    activeTemplateSteps: [],
    activeTemplateGroupBy: [],
    sideNavbarState: false,
    isStepsOverride: false,
    conditionalSteps: {},
    noTemplateFound: false,
    activeGroup: ''
}

export default defaultState
