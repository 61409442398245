import React from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

type Props = {
  title: string;
  dropDownState: boolean;
};

const NavDropdownTitle = ({
    title,
    dropDownState
}: Props): React.ReactElement => {
    return (
        <>
            <div>
                <svg width="15" height="15" viewBox="0 0 100 100" className="me-3 dote">
                    <circle cx="50" cy="50" r="50" stroke="#000" strokeWidth="0" />
                </svg>
                {title}
            </div>
            {dropDownState
                ? (
                    <BsChevronUp size={15} className="float-end" />
                )
                : (
                    <BsChevronDown size={15} className="float-end" />
                )}
        </>
    )
}

export default NavDropdownTitle
