import React, { useEffect, useState } from 'react'
import { TemplateObject } from '../../../Models/TemplateObject'
import { InputGroup } from 'react-bootstrap'
import { BsX } from 'react-icons/bs'

type Props = {
    onSubmitCheckboxDynamic: Function,
    removeCheckboxDynamic: Function,
    index: number,
    checkboxData: TemplateObject
}

const InputCheckboxDynamic = ({ onSubmitCheckboxDynamic, index, checkboxData, removeCheckboxDynamic }: Props) => {
    const [inputData, setInputData] = useState(checkboxData.text || '')
    useEffect(() => {
        setInputData(checkboxData.text)
        return () => { }
    }, [checkboxData.text])

    return (
        <InputGroup className="form-inline">
            <input defaultChecked={inputData !== '' || checkboxData.text} className={'form-check-input'} type="checkbox" />
            <input
                // readOnly={!checkboxData.isSubmit}
                autoFocus={checkboxData.isFocusEnabled}
                type="text"
                onChange={(event) => setInputData(event.target.value)}
                value={inputData}
                className={`form-control m-1 ${!checkboxData.isSubmit ? 'd-none' : ''}`}
                placeholder="Add your own here"
                onBlur={() => {
                    if (!checkboxData.isSubmit) return false
                    onSubmitCheckboxDynamic(inputData, index)
                }}
                onKeyPress={(event) => {
                    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                        onSubmitCheckboxDynamic(inputData, index)
                        return
                    }
                    return false
                }}
            />
            {!checkboxData.isSubmit &&
                (
                    <>
                        <label>{inputData}</label>
                        <BsX
                            size={20}
                            className="close-icon"
                            onClick={() => removeCheckboxDynamic(index)}
                        />
                    </>
                )}
        </InputGroup>
    )
}

export default InputCheckboxDynamic
