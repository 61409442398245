import React, { createContext, useReducer } from 'react'
import { sessionStorageKey } from '../Config/Constants'
import { logger } from '../Middleware/Logger'
import TemplateState from '../Models/TemplateState'
import defaultState from '../State/Template/defaultState'
import TemplateReducer from '../State/Template/Reducer'

const TemplateDataContext = createContext<TemplateState>({
    state: defaultState,
    dispatch: () => {}
})

type Props = {
    children:React.ReactNode
}

export const TemplateDataContextProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(TemplateReducer, defaultState, () => {
        try {
            const saveState = sessionStorage.getItem(sessionStorageKey.TEMPLATE_STATE)
            if (saveState) {
                return JSON.parse(saveState)
            }
            return defaultState
        } catch (error) {
            logger.error('🚀 ~ file: TemplateDataContext.context.tsx ~ line 18 ~ useReducer ~ error', error)
        }
    })

    return (
        <TemplateDataContext.Provider value={{ state, dispatch }}>
            {children}
        </TemplateDataContext.Provider>
    )
}

export default TemplateDataContext
