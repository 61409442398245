import React, { ReactElement, ReactNode } from 'react'

type TemplateOverlayProps = {
    children: ReactNode,
    actionElement?: ReactNode,
}

export default function TemplateOverlay ({ children, actionElement } : TemplateOverlayProps) : ReactElement {
    return (
        <div className='overlay-container'>
            {children}
            {/* OVERLAY */}
            <div className='overlay-layout'>
                {actionElement && actionElement}
            </div>
        </div>
    )
}
