import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { templateActionEnum } from '../../Config/Constants'
import TemplateDataContext from '../../Contexts/TemplateData.context'
import ToastComponent from '../Shared/ToastComponent'

type Props = {}

const Header = (props: Props) => {
    const { state, dispatch } = useContext(TemplateDataContext)
    const { show, message } = state.error

    return (
        <>
            {/* Toast Message for Errors */}
            <ToastComponent
                show={show || false}
                message={message || ''}
                closeHandler={() => dispatch({ type: templateActionEnum.SET_ERROR, payload: { show: false } })}
            />
            {/* Header */}
            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-auto text-center text-lg-left mt-2">
                            <Link to="/">
                                <img className="pdfsimpli-logo" src="/images/PDFSimpli-logo.svg" alt="PDFsimpli" draggable="false" />
                                <span className="sr-only">PDFsimpli</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
