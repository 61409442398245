import React, { useContext } from 'react'
import { templateActionEnum } from '../../Config/Constants'
import TemplateDataContext from '../../Contexts/TemplateData.context'
import Footer from '../Footer/Footer'
import ToastComponent from '../Shared/ToastComponent'

type Props = {
    mainComponent:React.ReactNode
}

const TemplateLayout = ({ mainComponent }:Props) => {
    const { state, dispatch } = useContext(TemplateDataContext)
    const { show, message } = state.error

    return (
        <>
            <ToastComponent
                show={show || false}
                message={message || ''}
                closeHandler={() => dispatch({ type: templateActionEnum.SET_ERROR, payload: { show: false } })}
            />
            <div className='container-fluid'>
                { mainComponent }
            </div>
            {/* Footer */}
            <Footer />
        </>
    )
}

export default TemplateLayout
