import React from 'react'
import ColorSelector from '../ColorSelector/ColorSelector'
import SliderComponent from '../Slider/SliderComponent'

export default function ChooseTemplate () {
    return (<section className="body-section">
        <div className="container">
            <div className="card-header headerheight">
            </div>
            <div className="title-wrap text-center">
                <h1 className="page-title mb-3">Choose the <b>best templates</b> for your needs</h1>
                <p className="sub-title mb-4">Pick from three templates ten colors.</p>
            </div>

            <div className="color-section">
                <ColorSelector />
            </div>

            <SliderComponent />
        </div>
    </section>)
}
