import React from 'react'

type Props = {}

const NoTemplate = (props:Props) => {
    return (
        <div id="steps-form" className="col-lg">
            <div className="vehicle-steps-content">
                <div className="tab">
                    <div id="step_id_0" className='row'>
                        <div className="col-md-12">
                            <div className="steps-mobile 0000 text-center">
                                <h4>Template Not Found !</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoTemplate
