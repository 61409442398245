import React, { ReactElement, useContext } from 'react'
import { jsonKeyMapping } from '../../../Config/Constants'
import FormDataContext from '../../../Contexts/FormData.context'
import { TemplateObject } from '../../../Models/TemplateObject'

type Props = {
    formElement: TemplateObject,
    handleInputChange:React.ChangeEventHandler<HTMLInputElement>
}

const InputTextMask = ({ formElement, handleInputChange }:Props) : ReactElement => {
    const { state } = useContext(FormDataContext)
    const { formData } = state
    /* Input handler */
    const inputHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        handleInputChange(e)
    }

    /* Append prefix/suffix on Blur Event */
    const appendFix = (e:React.ChangeEvent<HTMLInputElement>) => {
        let inputText = e.target.value
        if (inputText === '') {
            handleInputChange(e)
            return
        }
        if (formElement[jsonKeyMapping.FORM_PREFIX_SYMBOL]) {
            inputText = `${formElement[jsonKeyMapping.FORM_PREFIX_SYMBOL]} ${inputText}`
        }
        if (formElement[jsonKeyMapping.FORM_SUFFIX_SYMBOL]) {
            inputText = `${inputText} ${formElement[jsonKeyMapping.FORM_SUFFIX_SYMBOL]}`
        }
        e.target.value = inputText
        handleInputChange(e)
    }

    /* Append prefix/suffix on Focus Event */
    const removeFix = (e:React.ChangeEvent<HTMLInputElement>) => {
        let inputText = e.target.value
        if (inputText === '') {
            handleInputChange(e)
            return
        }
        if (
            formElement[jsonKeyMapping.FORM_PREFIX_SYMBOL] &&
            inputText.includes(formElement[jsonKeyMapping.FORM_PREFIX_SYMBOL])
        ) {
            inputText = inputText.replace(`${formElement[jsonKeyMapping.FORM_PREFIX_SYMBOL]} `, '')
            e.target.value = inputText
        }
        if (
            formElement[jsonKeyMapping.FORM_SUFFIX_SYMBOL] &&
            inputText.includes(formElement[jsonKeyMapping.FORM_SUFFIX_SYMBOL])
        ) {
            inputText = inputText.replace(` ${formElement[jsonKeyMapping.FORM_SUFFIX_SYMBOL]}`, '')
            e.target.value = inputText
        }
        handleInputChange(e)
    }

    return (
        <input
            id={formElement[jsonKeyMapping.FORM_ID]}
            className={`form-control ${
                formElement.inputClass ? formElement.inputClass : ''
            }`}
            placeholder={formElement[jsonKeyMapping.FORM_PLACEHOLDER] || ''}
            type={formElement.type}
            onChange={inputHandler}
            onFocus={removeFix}
            onBlur={appendFix}
            maxLength={formElement.maxLength || formElement[jsonKeyMapping.FORM_MAX_LENGTH] || null}
            value={formData[formElement[jsonKeyMapping.FORM_ID]] ? formData[formElement[jsonKeyMapping.FORM_ID]] : '' }
        />
    )
}

export default InputTextMask
