import React, { useContext } from 'react'
import { jsonKeyMapping } from '../../../Config/Constants'
import FormDataContext from '../../../Contexts/FormData.context'
import { TemplateObject } from '../../../Models/TemplateObject'
import InputWrapper from './InputWrapper'
import InputContext from '../../../Contexts/Input.Context'

type Props = {
    formElement: TemplateObject,
    handleTextAreaChange: React.ChangeEventHandler<HTMLTextAreaElement>
};

const InputTextArea = ({ formElement, handleTextAreaChange }: Props) => {
    const { state } = useContext(FormDataContext)
    const { setConditionalInput } = useContext(InputContext)
    const { formData } = state

    /* add more fields */
    const loadFields = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== '' && formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] !== undefined) {
            setConditionalInput({
                formElement: formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS],
                parentLabel: formElement[jsonKeyMapping.FORM_LABEL],
                value: formElement[jsonKeyMapping.FORM_VALUE]
            })
        } else {
            setConditionalInput(null)
        }
        handleTextAreaChange(e)
    }

    return (
        <InputWrapper
            formElement={formElement}
            label={formElement[jsonKeyMapping.FORM_LABEL] || ''}
        >
            <textarea
                id={formElement[jsonKeyMapping.FORM_ID]}
                className={`form-control ${formElement.inputClass ? formElement.inputClass : ''}`}
                placeholder={formElement[jsonKeyMapping.FORM_PLACEHOLDER]}
                onChange={loadFields}
                maxLength={formElement[jsonKeyMapping.FORM_MAX_LENGTH]}
                value={formData[formElement[jsonKeyMapping.FORM_ID]] ? formData[formElement[jsonKeyMapping.FORM_ID]] : ''}
            />
        </InputWrapper>
    )
}

export default InputTextArea
