import React, { useContext } from 'react'
import Slider from 'react-slick'
import TemplateDataContext from '../../Contexts/TemplateData.context'
import Template from '../Template/Template'

type Props = {}

const SliderComponent = (props: Props) => {
    const { state } = useContext(TemplateDataContext)
    const { templateKeys } = state

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: templateKeys.length,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: templateKeys.length
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    }
    return (
        <div className="row">
            <Slider {...settings}>
                {templateKeys.length > 0 && (
                    templateKeys.map((templateKey, index) => (
                        <div key={`${templateKey}${index}`} className={templateKeys.length === 1 ? 'template-width' : ''}>
                            <Template isInnerPage={false} templateKey={templateKey} index={index} />
                        </div>
                    ))
                )}
            </Slider>
        </div>
    )
}

export default SliderComponent
