import React, { useContext, useEffect } from 'react'
import FormDataContext from '../../../Contexts/FormData.context'
import { formActionEnum, jsonKeyMapping } from '../../../Config/Constants'
import { TemplateObject } from '../../../Models/TemplateObject'
import InputWrapper from './InputWrapper'
import InputContext from '../../../Contexts/Input.Context'

type Props = {
    formElement: TemplateObject;
    handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
};

const InputDateTime = ({ formElement, handleInputChange }: Props) => {
    const { state, dispatch } = useContext(FormDataContext)
    const { setConditionalInput } = useContext(InputContext)

    const { formData } = state

    useEffect(() => {
        if (formElement && formElement[jsonKeyMapping.FORM_DATE_TIME_FORMAT]) {
            const formElementData = {
                [`meta-data-${formElement[jsonKeyMapping.FORM_ID]}`]: {
                    timeFormat: formElement[jsonKeyMapping.FORM_DATE_TIME_FORMAT]
                }
            }

            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })
        }

        return () => {}
    }, [])

    /* add more fields */
    const loadFields = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== '' && formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] !== undefined) {
            setConditionalInput({
                formElement: formElement[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS],
                parentLabel: formElement[jsonKeyMapping.FORM_LABEL],
                value: formElement[jsonKeyMapping.FORM_VALUE]
            })
        } else {
            setConditionalInput(null)
        }
        handleInputChange(e)
    }

    return (
        <InputWrapper
            formElement={formElement}
            label={formElement[jsonKeyMapping.FORM_LABEL] || ''}>
            <input
                id={formElement[jsonKeyMapping.FORM_ID]}
                className={`form-control ${formElement.inputClass ? formElement.inputClass : ''
                }`}
                placeholder={formElement[jsonKeyMapping.FORM_PLACEHOLDER]}
                min={formElement.min ? formElement.min : null}
                max={formElement.max ? formElement.max : null}
                defaultValue={formElement.defaultDate}
                type="datetime-local"
                onChange={loadFields}
                value={formData[formElement[jsonKeyMapping.FORM_ID]] || ''}
            />
        </InputWrapper>
    )
}

export default InputDateTime
