import React, { useContext, useEffect, useState } from 'react'
import slugify from 'slugify'
import FormDataContext from '../../../Contexts/FormData.context'
import InputRadioDynamic from './InputRadioDynamic'
import { DEFAULT_RADIO_VALUE, formActionEnum, jsonKeyMapping, NO_GROUP, templateActionEnum } from '../../../Config/Constants'
import { TemplateObject } from '../../../Models/TemplateObject'
import TemplateDataContext from '../../../Contexts/TemplateData.context'
import { InputGroup } from 'react-bootstrap'
import InputWrapper from './InputWrapper'
import InputContext from '../../../Contexts/Input.Context'

type Props = {
    formElement: TemplateObject;
    handleRadioInput: Function;
    stepDetail?: TemplateObject;
};

const InputRadio = ({ formElement, handleRadioInput, stepDetail }: Props) => {
    const { state, dispatch } = useContext(FormDataContext)
    const { state: TemplateState, dispatch: templateDispatch } = useContext(TemplateDataContext)
    const { conditionalSteps, activeTemplateGroupBy, activeTemplateSteps } = TemplateState
    const { formData, stepData } = state
    const [inputValue, setInputValue] = useState('')
    const { setConditionalInput } = useContext(InputContext)
    const [textIsDisabled, setTextIsDisabled] = useState(true)
    const [inputRef, setInputRef] = useState(null)

    /* add meta data : conditional text show/hide */
    useEffect(() => {
        if (formElement) {
            const values = formElement.inputRadio.map((inputRadio: TemplateObject) => {
                if (inputRadio[jsonKeyMapping.FORM_VALUE] === '' || inputRadio[jsonKeyMapping.FORM_VALUE] === undefined) {
                    return DEFAULT_RADIO_VALUE
                }

                return inputRadio[jsonKeyMapping.FORM_VALUE]
            })
            const previousData = formData[`meta-data-${formElement[jsonKeyMapping.FORM_ID]}`] || {}
            const formElementData = {
                [`meta-data-${formElement[jsonKeyMapping.FORM_ID]}`]: {
                    ...previousData,
                    values
                }
            }

            dispatch({ type: formActionEnum.SET_FORM_DATA, payload: formElementData })
        }

        return () => { }
    }, [formElement])

    const handleDynamicInput = (
        id: string,
        label: string,
        isDynamic: boolean
    ) => {
        // for clearing and disable text input
        if (!isDynamic) {
            setInputValue('')
            setTextIsDisabled(true)
        } else {
            setConditionalInput(null)
            setInputValue(inputValue || '')
            setTextIsDisabled(false)
        }
    }

    const loadMoreFields = ({ moreFields, parentLabel, value }: { moreFields: TemplateObject; parentLabel: string; value: string; }): void => {
        if (moreFields[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS] &&
            moreFields[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS].length
        ) {
            setConditionalInput({
                formElement: moreFields[jsonKeyMapping.FORM_INPUT_RADIO_MORE_FIELDS],
                parentLabel,
                value
            })
        } else {
            setConditionalInput(null)
        }
        return handleDynamicInput(
            formElement[jsonKeyMapping.FORM_ID],
            moreFields.label,
            false
        )
    }

    const AddNewSubStep = ({ inputRadio }: { inputRadio: TemplateObject }): void => {
        /* check if any conditional step in present  */
        if (stepDetail === undefined || (stepDetail && conditionalSteps[stepDetail[jsonKeyMapping.SLUG]] === undefined)
        ) {
            return
        }

        /* check if any conditional step match the current selected input  */
        const step = conditionalSteps[stepDetail[jsonKeyMapping.SLUG]]
        const conditionalStepsList = step.filter((_step: TemplateObject) => (
            _step[jsonKeyMapping.SHOW_ON_FORM_ID] === formElement[jsonKeyMapping.FORM_ID] &&
            /* CHANGE FROM LABEL TO VALUE */
            _step[jsonKeyMapping.SHOW_ON_FORM_VALUE] === inputRadio[jsonKeyMapping.FORM_VALUE]
        ))
        if (conditionalStepsList.length === 0) {
            return
        }
        let group = NO_GROUP
        let isGroupAddDynamic = false
        /* Add Group Key if its present in step detail */
        const newStepDetails = conditionalStepsList.map((step: TemplateObject) => {
            const isParentGroupAddDynamic: undefined | boolean = stepDetail[jsonKeyMapping.IS_GROUP_ADD_DYNAMIC]
            /* parent step already have group and also child */
            if (step[jsonKeyMapping.GROUP] &&
                stepDetail[jsonKeyMapping.GROUP] &&
                isParentGroupAddDynamic === undefined
            ) {
                group = stepDetail[jsonKeyMapping.GROUP]
                return {
                    ...step,
                    [jsonKeyMapping.GROUP]: group,
                    [jsonKeyMapping.SLUGIFY]: slugify(
                        step[jsonKeyMapping.SLUG].toLowerCase()
                    )
                }
            }
            /* parent step doesn't have group but child have */
            if (step[jsonKeyMapping.GROUP] && !stepDetail[jsonKeyMapping.GROUP]) {
                group = stepDetail[jsonKeyMapping.SLUG]
                isGroupAddDynamic = true
                return {
                    ...step,
                    [jsonKeyMapping.GROUP]: group,
                    [jsonKeyMapping.SLUGIFY]: slugify(
                        step[jsonKeyMapping.SLUG].toLowerCase()
                    )
                }
            }
            /* parent step already have group and child not */
            if (!step[jsonKeyMapping.GROUP] &&
                stepDetail[jsonKeyMapping.GROUP] &&
                isParentGroupAddDynamic === undefined
            ) {
                group = stepDetail[jsonKeyMapping.GROUP]
                return {
                    ...step,
                    [jsonKeyMapping.GROUP]: group,
                    [jsonKeyMapping.SLUGIFY]: slugify(
                        step[jsonKeyMapping.SLUG].toLowerCase()
                    )
                }
            }

            return {
                ...step,
                [jsonKeyMapping.SLUGIFY]: slugify(
                    step[jsonKeyMapping.SLUG].toLowerCase()
                )
            }
        })
        /* Add metadata in formData */
        const metaData = {
            [`step-meta-data-${formElement[jsonKeyMapping.FORM_ID]}`]: {
                [jsonKeyMapping.FORM_VALUE]: inputRadio[jsonKeyMapping.FORM_VALUE],
                [jsonKeyMapping.GROUP]: group,
                [jsonKeyMapping.IS_GROUP_ADD_DYNAMIC]: isGroupAddDynamic,
                stepsTitle: newStepDetails.map((step: TemplateObject) => step[jsonKeyMapping.SLUG])
            }
        }
        dispatch({ type: formActionEnum.SET_STEP_DATA, payload: metaData })

        let newActiveTemplateGroupBy: TemplateObject[] = []
        /* Parent step already a Group  */
        if (stepDetail[jsonKeyMapping.GROUP] !== undefined && stepDetail.isGroupAddDynamic === undefined) {
            const groupIndex = activeTemplateGroupBy.findIndex(step => step.groupName && step.groupName.toLowerCase() === stepDetail[jsonKeyMapping.GROUP].toLowerCase())
            const groupElement = activeTemplateGroupBy[groupIndex]
            const groupStepList: TemplateObject[] = activeTemplateGroupBy[groupIndex][groupElement.groupName]
            const stepIndex = groupStepList.findIndex(step => step[jsonKeyMapping.SLUG] === stepDetail[jsonKeyMapping.SLUG])
            const modifiedIndex = stepIndex + 1
            const modifiedGroupList = {
                groupName: stepDetail[jsonKeyMapping.GROUP],
                [stepDetail[jsonKeyMapping.GROUP]]: [
                    ...groupStepList.slice(0, modifiedIndex),
                    ...newStepDetails,
                    ...groupStepList.slice(modifiedIndex)
                ]
            }
            newActiveTemplateGroupBy = activeTemplateGroupBy.map((step, index: number) => {
                if (index === groupIndex) return modifiedGroupList
                return step
            })
        }
        /* Parent step already a Group but its dynamically added */
        if (stepDetail[jsonKeyMapping.GROUP] !== undefined && stepDetail.isGroupAddDynamic === true) {
            const groupIndex = activeTemplateGroupBy.findIndex(step => step.groupName === stepDetail[jsonKeyMapping.GROUP])
            const modifiedIndex = groupIndex + 1
            newActiveTemplateGroupBy = [
                ...activeTemplateGroupBy.slice(0, modifiedIndex),
                ...newStepDetails,
                ...activeTemplateGroupBy.slice(modifiedIndex)
            ]
        }

        /* Parent Doesn't Any Group */
        if (stepDetail[jsonKeyMapping.GROUP] === undefined) {
            const stepIndex = activeTemplateGroupBy.findIndex(step => step[jsonKeyMapping.SLUG] === stepDetail[jsonKeyMapping.SLUG])
            if (group !== NO_GROUP) {
                const modifiedStep: TemplateObject = {
                    groupName: group,
                    [group]: [{ ...activeTemplateGroupBy[stepIndex], group, isGroupAddDynamic: true }, ...newStepDetails]
                }
                newActiveTemplateGroupBy = activeTemplateGroupBy.map((step, index: number) => {
                    if (index === stepIndex) return modifiedStep
                    return step
                })
            } else {
                const modifiedIndex = stepIndex + 1
                newActiveTemplateGroupBy = [
                    ...activeTemplateGroupBy.slice(0, modifiedIndex),
                    ...newStepDetails,
                    ...activeTemplateGroupBy.slice(modifiedIndex)
                ]
            }
        }
        /* update the state */
        if (newActiveTemplateGroupBy.length > 0) {
            /* update the active steps */
            templateDispatch({ type: templateActionEnum.SET_STEP_FLAG, payload: true })
            templateDispatch({ type: templateActionEnum.UPDATE_ACTIVE_TEMPLATE_STEP, payload: newActiveTemplateGroupBy })
        }
    }

    const removeStep = ({ inputRadio }: { inputRadio: TemplateObject }): void | Boolean => {
        /* check if a  Conditional step added using current formElement */
        const key = `step-meta-data-${formElement[jsonKeyMapping.FORM_ID]}`
        if (stepData[key] === undefined || stepDetail === undefined) return false
        const value = stepData[key].value

        if (value === inputRadio.value) return false

        const stepsTitle: string[] = stepData[key].stepsTitle

        /* check nested step : ONE LEVEL */
        const nestedSteps: string[] = []
        stepsTitle.forEach((title: string) => {
            const dependConditionalStep = conditionalSteps[title]
            if (!dependConditionalStep) return
            nestedSteps.push(...dependConditionalStep.map((step: TemplateObject) => step[jsonKeyMapping.SLUG]))
        })
        const removeStepList = [...stepsTitle, ...nestedSteps]

        const isGroupAddDynamic = stepData[key].isGroupAddDynamic
        /* remove the steps */
        let newActiveTemplateGroupBy: TemplateObject[] = []
        /* Parent Have Group */
        if (stepDetail[jsonKeyMapping.GROUP] !== undefined && stepDetail.isGroupAddDynamic === undefined) {
            const groupIndex = activeTemplateGroupBy.findIndex(step => step.groupName === stepDetail[jsonKeyMapping.GROUP])
            const groupStepList: TemplateObject[] = activeTemplateGroupBy[groupIndex][stepDetail[jsonKeyMapping.GROUP]]
            const modifiedGroupList = {
                groupName: stepDetail[jsonKeyMapping.GROUP],
                [stepDetail[jsonKeyMapping.GROUP]]: groupStepList.filter(step => !removeStepList.includes(step[jsonKeyMapping.SLUG]))
            }
            newActiveTemplateGroupBy = activeTemplateGroupBy.map((step, index: number) => {
                if (index === groupIndex) return modifiedGroupList
                return step
            })
        }

        /* Dynamically added Group */
        if (stepDetail[jsonKeyMapping.GROUP] !== undefined && stepDetail.isGroupAddDynamic === true) {
            const groupIndex = activeTemplateGroupBy.findIndex(step => step.groupName === stepDetail[jsonKeyMapping.GROUP])
            const groupStepList: TemplateObject[] = activeTemplateGroupBy[groupIndex][stepDetail[jsonKeyMapping.GROUP]]
            if (isGroupAddDynamic) {
                const filterSteps = groupStepList.filter(step => !removeStepList.includes(step[jsonKeyMapping.SLUG]))
                const removeGroupStep = filterSteps.map(step => {
                    /* remove dynamic keys */
                    const { group, isGroupAddDynamic, ...restStepDetail } = step
                    return restStepDetail
                })
                newActiveTemplateGroupBy = [
                    ...activeTemplateGroupBy.slice(0, groupIndex),
                    ...removeGroupStep,
                    ...activeTemplateGroupBy.slice(groupIndex + 1)
                ]
            }

            if (!isGroupAddDynamic) {
                newActiveTemplateGroupBy = activeTemplateGroupBy.filter(step => !removeStepList.includes(step[jsonKeyMapping.SLUG]))
            }
        }

        /* Parent doesn't have a Group */
        if (stepDetail[jsonKeyMapping.GROUP] === undefined) {
            newActiveTemplateGroupBy = activeTemplateGroupBy.filter(step => !removeStepList.includes(step[jsonKeyMapping.SLUG]))
            /* FIND AS GROUP ELEMENT */
            removeStepList.forEach(removeStep => {
                newActiveTemplateGroupBy = newActiveTemplateGroupBy.filter(step => (step.groupName !== removeStep))
            })
        }

        if (newActiveTemplateGroupBy.length > 0) {
            /* UPDATE THE ACTIVE STEPS */
            templateDispatch({ type: templateActionEnum.SET_STEP_FLAG, payload: true })
            templateDispatch({ type: templateActionEnum.UPDATE_ACTIVE_TEMPLATE_STEP, payload: newActiveTemplateGroupBy })
            dispatch({ type: formActionEnum.REMOVE_STEP_DATA, payload: [key] })
        }

        /* remove conditional formData   */
        if (stepDetail !== undefined) {
            /* remove formData  */
            const keys: string[] = []

            activeTemplateSteps
                .filter((_step: TemplateObject) => removeStepList.includes(_step[jsonKeyMapping.SLUG]))
                .forEach((step: TemplateObject) => {
                    keys.push(
                        ...step[jsonKeyMapping.FORM_GROUP].map(
                            (formElement: TemplateObject) =>
                                formElement[jsonKeyMapping.FORM_ID]
                        )
                    )
                })
            if (keys.length > 0) {
                dispatch({ type: formActionEnum.REMOVE_FORM_DATA, payload: keys })
            }
        }
        /* REMOVE STEP DATA */

        /* REMOVE NESTED STEP DATA */
        const removeStepGroup: string[] = []

        for (const conditionalStep in stepData) {
            const stepsTitle = stepData[conditionalStep].stepsTitle
            const isPresentStepsNestedList = stepsTitle.filter((stepTitle: string) => nestedSteps.includes(stepTitle))
            if (isPresentStepsNestedList.length > 0) removeStepGroup.push(conditionalStep)
        }
        /* END REMOVE NESTED STEP DATA  */

        dispatch({ type: formActionEnum.REMOVE_FORM_DATA, payload: [key, ...removeStepGroup] })
    }

    const loadField = ({ inputRadio }: { inputRadio: TemplateObject }) => {
        /* Add Conditional Step if present */
        AddNewSubStep({ inputRadio })
        /* Add dynamic fields if present */
        loadMoreFields({
            moreFields: inputRadio,
            /* CHANGE FROM LABEL TO VALUE */
            parentLabel: inputRadio.value,
            value: inputRadio.value
        })

        /* clean up to handle remove conditional step */
        removeStep({ inputRadio })
        /* Set the formData */
        handleRadioInput(
            formElement[jsonKeyMapping.FORM_ID],
            inputRadio[jsonKeyMapping.FORM_LABEL],
            inputRadio[jsonKeyMapping.FORM_VALUE]
        )
    }

    return (
        <InputWrapper
            formElement={formElement}
            label={formElement[jsonKeyMapping.FORM_LABEL] || ''}
        >
            {formElement.inputRadio && formElement.inputRadio.map((inputRadioObject: TemplateObject, index: number) => {
                const inputRadio = { ...inputRadioObject }
                if (!inputRadioObject[jsonKeyMapping.FORM_VALUE]) {
                    inputRadio.value = DEFAULT_RADIO_VALUE
                }
                const id = index > 0 ? `${inputRadio[jsonKeyMapping.FORM_NAME]}_${index}` : formElement[jsonKeyMapping.FORM_ID]
                return (
                    <InputGroup
                        key={`${formElement[jsonKeyMapping.FORM_ID]}_${index}`}
                        className={`flex-nowrap radio-input ${formElement.inputRadio.length < 3 ? 'd-inline' : 'align-items-baseline'} `}
                    >
                        <input
                            id={id}
                            className={`form-radio-input ${formElement.inputClass ? formElement.inputClass : ''}`}
                            type="radio"
                            name={formElement[jsonKeyMapping.FORM_ID]}
                            value={inputRadio[jsonKeyMapping.FORM_VALUE]}
                            onChange={() => loadField({ inputRadio })}
                            /* CHANGE FROM LABEL TO VALUE */
                            checked={(formData[formElement[jsonKeyMapping.FORM_ID]] && formData[formElement[jsonKeyMapping.FORM_ID]] === inputRadio.value) || false}
                        ></input>
                        <label key={index} htmlFor={id} className="form-radio-input input-radio-label mx-2">
                            {inputRadio.label}
                        </label>
                    </InputGroup>
                )
            })}
            {formElement.isSetYourOwn ? <InputRadioDynamic inputRef={inputRef} setInputRef={setInputRef} inputValue={inputValue} setInputValue={setInputValue} textIsDisabled={textIsDisabled} setTextIsDisabled={setTextIsDisabled} formElement={formElement} handleDynamicInput={handleDynamicInput} parentClass='input-dynamic-radio' /> : null}
        </InputWrapper>
    )
}

export default InputRadio
