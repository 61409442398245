import { colors } from '../../Config/Constants'
import { FormContext } from '../../Models/FormContext'

const defaultState:FormContext = {
    formData: {},
    blankFormData: {},
    activeColor: colors[0],
    stepData: {},
    docId: '',
    paragraph: { paraContentId: '', paraId: '' }
}

export default defaultState
