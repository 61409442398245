import React from 'react'

type Props = {}

const TermsConditions = (props: Props) => {
    return (
        <div>TermsConditions</div>
    )
}

export default TermsConditions
