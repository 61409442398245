import React from 'react'

type Props = {}

const ContactUs = (props: Props) => {
    return (
        <div>ContactUs</div>
    )
}

export default ContactUs
