import React, { useContext } from 'react'
import TemplateDataContext from '../../Contexts/TemplateData.context'
import { BsFillEyeFill } from 'react-icons/bs'
import { templateActionEnum } from '../../Config/Constants'

type Props = {}

const PreviewComponent = (props: Props) => {
    const { state, dispatch } = useContext(TemplateDataContext)

    const handleShow = () => {
        dispatch({ type: templateActionEnum.SET_MODAL_STATE, payload: !state.showTemplateModal })
    }

    return (
        <div className="d-flex align-items-center pe-3 pointer" onClick={handleShow}>
            <BsFillEyeFill className="icons me-2 blue-color" size={24} />
            <p className="blue-color text-decoration-underline sm-font fw-600">
                Preview
            </p>
        </div>
    )
}

export default PreviewComponent
