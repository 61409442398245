import _ from 'lodash'
import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { jsonKeyMapping, NO_GROUP, templateActionEnum } from '../Config/Constants'
import TemplateDataContext from '../Contexts/TemplateData.context'
import { TemplateObject } from '../Models/TemplateObject'

type useLoadStepsType = {
    templates:TemplateObject
}
export default async function useLoadSteps ({ templates } : useLoadStepsType): Promise<void> {
    const { stepName, template } = useParams()
    const { state, dispatch } = useContext(TemplateDataContext)
    const { activeTemplateSteps, isStepsOverride } = state

    useEffect(() => {
        if (template && Object.keys(templates).length > 0) {
            /* ignore :  if any conditional step is added */
            if (isStepsOverride) return
            const selectTemplate = templates[template]

            /* Conditional Steps */
            const conditionalSteps = _.groupBy(selectTemplate.steps.filter((step:TemplateObject) => (step[jsonKeyMapping.PARENT] !== undefined)), jsonKeyMapping.PARENT)
            dispatch({ type: templateActionEnum.SET_CONDITIONAL_STEPS, payload: conditionalSteps })
            /* filter non conditional steps */
            const stepList = selectTemplate.steps.filter((step:TemplateObject) => step[jsonKeyMapping.PARENT] === undefined)
            const groupByStepsObject = _.groupBy(stepList, x => _.capitalize(x[jsonKeyMapping.GROUP] || NO_GROUP))

            const newGroupListSteps:TemplateObject[] = []
            Object.keys(groupByStepsObject).forEach(group => {
                if (group.toLowerCase() === NO_GROUP.toLowerCase()) {
                    newGroupListSteps.push(...groupByStepsObject[group])
                    return
                }
                newGroupListSteps.push({ groupName: group, [group]: groupByStepsObject[group] })
            })
            dispatch({ type: templateActionEnum.SET_ACTIVE_TEMPLATE_GROUP_BY, payload: newGroupListSteps })

            /* update the steps order */
            const steps = _.flatten(Object.values(groupByStepsObject))
            dispatch({ type: templateActionEnum.SET_ACTIVE_TEMPLATE_STEPS, payload: steps })
        }

        return () => {}
    }, [stepName, template, activeTemplateSteps.length, Object.keys(templates).length])
}
