import React, { ReactElement } from 'react'
import FormBuilder from '../../../FormBuilder/FormBuilder'
import { BsX } from 'react-icons/bs'
import { TemplateObject } from '../../../../Models/TemplateObject'
import { jsonKeyMapping } from '../../../../Config/Constants'

type Props = {
    inputFields: TemplateObject
    removeInputField: Function
}

const AddMoreElements = ({ inputFields, removeInputField }: Props): ReactElement => {
    return (<>
        {
            Object.values(inputFields).map((input, index) => {
                return (
                    <div key={index} className="mt-3 position-relative card add-more-body">
                        <FormBuilder fieldIndex={index + 1} formGroup={[input]} />
                        <BsX
                            className="close-icon mb-2 m-2 position-absolute top-0 end-0 "
                            onClick={() => removeInputField(input[jsonKeyMapping.FORM_ID])}
                        />
                    </div>
                )
            })
        }
    </>)
}

export default AddMoreElements
